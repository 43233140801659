import React, { useEffect } from "react";
import $ from "jquery";
import * as firebase from "utils/firebase.config";
import * as F from "utils/fns";
import _ from "lodash-es";
import axios from "axios";
import dayjs from "dayjs";
import { v4 as uuid4 } from "uuid";
import config from "utils/config";

// SECTION: 파이어베이스 (Firebase)

// SECTION: 일반
// NOTE: 사이즈잼
export function getElementSize(target) {
  if (target) {
    const { clientWidth, clientHeight } = target;
    return { x: clientWidth, y: clientHeight };
  }
  return { x: null, y: null };
}

// NOTE:  fadeOut

// NOTE: 로딩을 보여줍니다.
export function showLoading(config = {}) {
  console.log("showLoading");
  console.log(config);
  const setting = {
    id: ".FullScreenLoader",
    // opacity: 1,
    ...config,
  };

  if (setting.opacity) {
    $(".full-screen-dim").css({ opacity: setting.opacity });
  }
  $(setting.id).fadeIn(0);
  // $(id).fadeIn(100).css({ display: "flex" });
}

/**
 * NOTE: 로딩을 감춥니다.
 * @param {*} id
 * @param {*} delay
 */
export function hideLoading(config = {}) {
  const setting = {
    id: ".FullScreenLoader",
    // opacity: 1,
    delay: 0,
    ...config,
  };

  if (setting.opacity) {
    $(".full-screen-dim").css({ opacity: setting.opacity });
  }

  if (setting.delay) {
    $(setting.id).delay(setting.delay).fadeOut(300);
  } else {
    $(setting.id).fadeOut(300);
  }
}
// NOTE: 텔레그램
export function sendTelegramAlert(props) {
  const {
    username = "-",
    payMonth = "1",
    price = "-",
    phone = "-",
    email = "-",
    file = "",
    company = "",
    content = "",
    job = "",
    category = "",
    website = "",
    message,
    contract_id,
    ACCESS_URL,
    CHAT_ID,
    type,
  } = props;

  let setMessage = "";
  if (type === "qna") {
    setMessage += "[문의하기]\n";
    if (message) {
      setMessage += message;
    } else {
      setMessage += `${username}님의 문의하기 메일이 도착하였습니다.\n`;
    }
    setMessage += `${dayjs().format("YY.MM.DD (ddd) A HH:mm:ss")}\n\n`;
    setMessage += `연락처: ${phone}\n`;
    setMessage += `이메일 : ${email}\n`;
    setMessage += `첨부파일 : ${!!file ? "있음" : "없음"}\n`;
  }
  if (type === "pay") {
    setMessage += "[결제]\n";
    if (message) {
      setMessage += message;
    } else {
      setMessage += `${username}님이 ${F.numberWithCommas(
        price
      )}원을 결제하였습니다.`;
    }
    setMessage += `\n${dayjs().format("YY.MM.DD (ddd) A HH:mm:ss")}\n\n`;
    setMessage += `연락처: ${phone}\n`;
    setMessage += `이메일 : ${email}\n`;
    setMessage += `할부 : ${payMonth === "1" ? "일시불" : payMonth + "개월"}\n`;
  }
  if (type === "consulting_qna") {
    setMessage += "■상담요청■\n";
    if (message) {
      setMessage += message;
    } else {
      setMessage += `${username}님의 상담을 요청하였습니다.\n`;
    }
    setMessage += `${dayjs().format("YY.MM.DD (ddd) A HH:mm:ss")}\n\n\n`;
    //
    setMessage += `■고객정보■\n\n`;
    setMessage += `회사명: ${company}\n`;
    setMessage += `직책: ${job}\n`;
    setMessage += `이름: ${username}\n`;
    setMessage += `참고사이트 URL : ${website}\n`;
    setMessage += `연락처: ${phone}\n`;
    setMessage += `이메일 : ${email}\n\n\n`;
    //
    setMessage += `■프로젝트 정보■\n\n`;
    setMessage += `의뢰 서비스 : ${category}\n`;
    setMessage += `제작 예산 : ${price}만원\n\n\n`;

    setMessage += `■문의사항 및 설명■\n`;
    setMessage += `\n ${content}\n\n`;
  }
  if (type === "contract") {
    setMessage += "■계약완료■\n\n";
    if (message) {
      setMessage += message;
    } else {
      setMessage += `${username}님의 계약을 완료하였습니다.\n`;
    }
    setMessage += `${dayjs().format("YY.MM.DD (ddd) A HH:mm:ss")}\n`;
    setMessage += `Contract ID : ${contract_id}\n\n\n`;
    //
    setMessage += `■고객정보■\n\n`;
    setMessage += `회사명: ${company}\n`;
    setMessage += `이름: ${username}\n`;
    setMessage += `연락처: ${phone}\n`;
    setMessage += `이메일 : ${email}\n\n`;
  }

  if (type === "consulting_qna") {
    axios({
      method: "post",
      url: ACCESS_URL,
      data: {
        chat_id: CHAT_ID,
        text: setMessage,
        // parse_mode: "html",
      },
    })
      .then((res) => {
        console.log("send telegram");

        // let contentMessage = `[프로젝트 설명 및 문의사항] : \n\n`;
        // contentMessage += `\n ${content}\n`;
        // axios({
        //   method: "post",
        //   url: ACCESS_URL,
        //   data: {
        //     chat_id: CHAT_ID,
        //     text: contentMessage,
        //   },
        // })
        //   .then((res) => {
        //     console.log("send telegram");
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    axios({
      method: "post",
      // method: "get",
      url: ACCESS_URL,
      // params: {
      //   chat_id: CHAT_ID,
      //   text: setMessage,
      // },

      data: {
        chat_id: CHAT_ID,
        text: setMessage,
        // parse_mode: "markdown ",
      },
    })
      .then((res) => {
        console.log("send telegram");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return;
}
