import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

function NotFound() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <Styled.NotFound>
      <div className="NotFound__container">
        <div className="NotFound__box">
          <div className="NotFound__title">404</div>
          <div className="NotFound__info">the page not found</div>
          <div className="NotFound__btn_box">
            <Button variant="outlined" onClick={handleClick}>
              go back
            </Button>
            <Button variant="outlined">
              <NavLink to="/">Home</NavLink>
            </Button>
          </div>
        </div>
      </div>
    </Styled.NotFound>
  );
}

const Styled = {
  NotFound: styled.div`
    .NotFound__container {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .NotFound__box {
      text-align: center;
    }
    .NotFound__title {
      font-size: 45px;
      font-weight: bold;
    }
    .NotFound__info {
      margin-top: 20px;
      font-size: 18px;
    }
    .NotFound__btn_box {
      display: flex;
      gap: 10px;
      margin-top: 20px;
    }
  `,
};

export default NotFound;
