import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as Firebases from "utils/firebase";
import { GlobalStyle } from "styles/common";
import * as Pages from "pages";
import * as Urls from "utils/urls";
import * as Hooks from "utils/hooks";
import {
  // HashRouter as Router,
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { ProtectedRoute } from "components/common";
import { useSelector, useDispatch } from "react-redux";
import * as AuthActions from "store/modules/auth";
import * as Loaders from "components/loader";
import * as Portal from "components/portal";
/**
 *
 * @returns
 */
const customHistory = createBrowserHistory();

function App() {
  const AuthReducer = useSelector((state) => state.auth);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  console.log(AuthReducer?.data);
  const [isAuthenticated, isLogged] = Hooks.useAuthenticated({
    isNotAuthenticatedRedirect: "/login",
    baseAction: AuthActions.isSessionAlive,
    hasLoggedUserInfo: !!AuthReducer?.data.user_info?.email,
    isPageLoad: AuthReducer?.page_load === "load_complete",
  });

  const initKakao = () => {
    const jsKey = process.env.REACT_APP_KAKAO_LOGIN_JAVASCRIPT_KEY;
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
      console.log(Kakao.isInitialized());
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  return (
    <>
      {!isLogged && <Loaders.FullScreenLoader />}

      <>
        <Routes>
          <Route path={"/"} element={<Pages.Home />} />
          <Route path={"/login"} element={<Pages.Login />} />
          {/* <Route path={"/signup"} element={<Pages.Signup />} /> */}
          {/* <Route path={"/reset_password"} element={<Pages.Home />} />
          <Route path={"/varify_signup"} element={<Pages.VarifySignup />} /> */}
          <Route
            path={"/"}
            element={
              <ProtectedRoute
                redirectPath={"/login"}
                isAllowed={isAuthenticated}
                isLogged={isLogged}
              />
            }
            end
          >
            <Route path={Urls.routers.main} element={<Pages.Home />} />
            <Route path={"/dashboard"} element={<Pages.Dashboard />} />
            <Route path={"/setting"} element={<Pages.Setting />} />
            <Route path={"/sales_list"} element={<Pages.SalesList />} />
            {/* <Route path={"/signout"} element={<Pages.Home />} /> */}
            {/* <Route path={"/change_password"} element={<Pages.Home />} /> */}
          </Route>

          <Route path={"*"} element={<Pages.NotFound />} />
        </Routes>
        <Portal.AppPortal />
        <GlobalStyle />
      </>
    </>
  );
}

export default App;

// const dispatch = useDispatch();
// const navigate = useNavigate();
// const AuthReducer = useSelector((state) => state.auth);

// useEffect(() => {
//   //로그인 체크해서 있으면 로그인하고 없으면 페이지만 로드
//   console.log("useEffect isSessionAlive");
//   dispatch(actions.isSessionAlive());
// }, []);
// Hooks.useUpdateEffect(() => {
//   if (isLogged) {
//     // console.log(!!accessToken, isAuthComplete, isLogged);
//     if (isAuthComplete) {
//       dispatch(AuthActions.pageLoad(true));
//     } else {
//       // dispatch(AuthActions.pageLoad(true));
//       dispatch(AuthActions.pageLoad(true));
//       navigate("/login");
//     }
//   }
// }, [isAuthComplete, isLogged]);

// console.log("isPageLoadisPageLoad");
// console.log(isPageLoad);
