import React from "react";
import styled from "styled-components";

function DashboardLayout(props) {
  return (
    <Styled.DashboardLayout>
      <div>{props.nav}</div>
      <div className="DashboardLayout__main">
        {props.header}
        {props.main || props.children}
      </div>
    </Styled.DashboardLayout>
  );
}

const Styled = {
  DashboardLayout: styled.div`
    display: flex;
    min-height: 100%;
    .DashboardLayout__main {
      width: 100%;
      flex-grow: 1;
      /* padding: 100px 16px; */
      padding: 20px 25px;

      width: calc(100% - 280px);
    }
  `,
};

export default DashboardLayout;
