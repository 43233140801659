import React, { useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import * as validate from "utils/validate";
import { useImmer } from "use-immer";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useSelector, useDispatch } from "react-redux";
import * as C from "utils/constants";
import * as Loaders from "components/loader";
import * as Firebases from "utils/firebase";
import { useNavigate, NavLink } from "react-router-dom";
import * as Urls from "utils/urls";
import * as Libs from "utils/lib";
import circle_logo from "assets/images/image/circle_logo.png";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getRedirectResult,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
// import * as admin from "firebase-admin";

// NOTE:
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://thebornsoft.com/" target={"_blank"}>
        THEBORN SOFT.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

/**
 *
 * @param {*} props
 * @returns
 */

function SignupContainer(props) {
  const { onSubmit } = props;
  const SignupState = {
    is_loaded: null,
    signed: false,
    send_varify: false,
  };
  const formRef = useRef();
  const [values, setValues] = useImmer(SignupState);
  const adminAuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const isLoading =
  //   adminAuthReducer?.data.Signup.is_logged === C.PAGE_LOADING.LOAD_START;

  // NOTE:
  const preventRefresh = (event) => {
    if (
      (event.ctrlKey == true && (event.keyCode == 78 || event.keyCode == 82)) ||
      event.keyCode == 116
    ) {
      console.log("이 페이지는 F5키 사용이 막혀있습니다.");
      event.keyCode = 0;
      event.cancelBubble = true;
      event.returnValue = false;
      event.preventDefault();
      return false;
    }
    return false;
  };

  // NOTE: 처음 로딩
  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ""; //Chrome에서 동작하도록; deprecated
    // alert("브라우저 종료!");
    console.log("브라우저 종료!");
    let auth = getAuth();
    let user = auth.currentUser;
    console.log(user);
    CheckendSignout();
  };
  // NOTE:
  const handleClick = async (_config) => {
    const { typed } = _config;
    const data = new FormData(formRef.current);

    const formData = {
      email: data.get("email"),
      password: data.get("password"),
      username: data.get("username"),
    };

    // console.log(formData);

    // 구글로 회원가입
    if (typed === "google_signup") {
      Firebases.signupWithSocial({
        provider: "google",
        collectionName: "partners_users",
      }).then((res) => {
        // console.log("signupWithSocial");
        // console.log(res);
        if (res.isError === false) {
          if (res.message === "signup/already-email") {
            alert("이미 가입 된 이메일입니다.");
            return;
          } else {
            alert("회원가입을 환영합니다.");

            const formData = {
              email: res?.user?.email,
              user: res?.user,
              provider: "google",
            };
            dispatch(AuthActions.login(formData)).then((res) => {
              if (res.isError === false) {
                navigate("/dashboard");
              } else {
                alert("아이디와 비밀번호를 확인해주세요");
                return;
              }
            });

            return;
          }
        }
      });
    }
    if (typed === "mail_varify") {
      console.log("메일 인증 전송");
      console.log(formRef.current);

      // 이미 이메일 회원가입 되어있는지 확인

      // NOTE: 회원가입 후 이메일 인즌 전송

      const userEmail = formData.email;
      Libs.showLoading({
        opacity: "0.5",
      });

      await Firebases.joinWithVerification(
        formData.email,
        formData.password,
        "varify"
      ).then(async (res) => {
        console.log("response joinWithVerification");
        console.log(res);
        console.log(res?.origin.config.apiKey);

        setValues((draft) => {
          draft.send_varify = true;
        });
        if (res?.isError === false) {
          // const { isError: addDocError } = await Firebases.addFirebaseDoc({
          //   collectionName: "partners_users",
          //   data: {
          //     email: formData.email,
          //     password: formData.password,
          //     varifyApiKey: res?.origin.config.apiKey,
          //     uidIndex: [res?.user?.uid],
          //   },
          // });
          // if (addDocError !== false) {
          //   console.log("회원 추가 에러");
          //   Libs.hideLoading();
          //   return;
          // } else {
          //   console.log("회원 디비 추가");
          // }
          // const { isError: signoutError } =
          //   await Firebases.deleteFirebaseUser();
          // if (signoutError === false) {
          //   console.log("회원 탈퇴 완료");
          // } else {
          //   console.log("회원 탈퇴 실패, 재 로그인 후 시도해주세요.");
          // }
        }
      });
      Libs.hideLoading();
    }
    if (typed === "signup") {
      console.log("회원가입 시도");
      // console.log(formRef.current);

      const data = new FormData(formRef.current);

      console.log(formData);

      // return;

      Libs.showLoading({ opacity: 0.5 });
      // 이미 이메일 회원가입 되어있는지 확인
      await Firebases.loginWithEamil({
        email: formData.email,
        password: formData.password,
      }).then((res) => {
        console.log(res);
        console.log("loginWithEamil");
        console.log(res?.user?.uid);

        if (res.isError === false) {
          if (res?.user?.uid) {
            if (!res?.user?.emailVerified) {
              alert("이메일 인증을 완료해주세요.");
              return;
            }

            Firebases.addFirebaseDoc({
              collectionName: "partners_users",
              data: {
                email: formData.email,
                username: formData.username,
                uidIndex: [res?.user?.uid],
              },
            }).then((_res) => {
              console.log(_res);
              console.log(_res.origin?.id);
              if (_res?.isError === false) {
                Firebases.updateFirebaseDoc({
                  collectionName: "partners_users",
                  documentId: _res.origin?.id,
                  data: {
                    document_id: _res.origin?.id,
                  },
                });

                alert("회원가입이 완료되었습니다.");
                setValues((draft) => {
                  draft.signed = true;
                });
              }
            });
          }

          // navigate("/login");

          // if (res.user.emailVerified) {
          //   console.log("인증완료, 계정삭제");
          //   Firebases.deleteFirebaseUser().then((_res) => {
          //     console.log("deleteFirebaseUser");
          //     console.log(_res);
          //     console.log(formData);
          //     Firebases.registerWithEamil({
          //       email: formData.email,
          //       password: formData.password,
          //     }).then((__res) => {
          //       console.log("registerWithEamil");
          //       console.log(__res);
          //       if (__res?.isError === false) {
          //         alert("회원가입 완료");
          //       } else {
          //         alert("실패");
          //       }
          //     });
          //   });
          // } else {
          //   alert("메일 인증을 완료해주세요.");
          // }
        } else {
          alert("이메일 인증을 완료해주세요.");
        }
      });

      Libs.hideLoading();
      //

      // 이메일 인즌 전송
      // Firebases.registerWithEamil(formData.email, formData.password).then(
      //   (res) => {
      //     console.log(res);
      //   }
      // );
    }
  };

  useEffect(() => {
    Firebases.onUserChanged(function (res) {
      console.log("onUserChanged");
      console.log(res);
    });
    setValues((draft) => {
      draft.is_loaded = true;
    });

    (() => {
      window.addEventListener("beforeunload", preventClose);
      document.addEventListener("keydown", preventRefresh);
    })();

    return () => {
      window.removeEventListener("beforeunload", preventClose);
      document.removeEventListener("keydown", preventRefresh);
      // CheckendSignout();
    };
  }, []);

  const isLoaded = values.is_loaded;
  useEffect(() => {
    if (values.signed === true) {
      navigate("/login");
    }

    return () => {
      // console.log(values.is_loaded);
      // console.log((values.is_loaded && !values.signed) || values.send_varify);
      console.log(values);

      // if (values.is_loaded && !values.signed && values.send_varify) {
      //   CheckendSignout();
      // }

      // if (values.is_loaded && !values.signed && !values.send_varify) {
      //   console.log("아무것도 안함, 새로고침 가능성");
      // }
    };
  }, [values.send_varify, values.is_loaded, values.signed]);

  function CheckendSignout() {
    let auth = getAuth();
    let user = auth.currentUser;
    console.log(user);
    if (user?.emailVerified) {
      console.log("인증 완료 가입 완료");
      alert("회원가입을 축하드립니다.");
      return;
    }
    console.log("페이지 이탈");
    Firebases.signOutFirebaseUser({
      collectionName: "partners_users",
    }).then((res) => {
      if (res?.isError === false) {
        console.log("회원 탈퇴 완료");
      } else {
        console.log("회원 탈퇴 실패, 재 로그인 후 시도해주세요.");
      }
    });
  }

  // console.log(values);

  return (
    <Styled.Signup>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Signup__logo">
              <img
                src={circle_logo}
                alt="로그인 로고"
                className="Signup__logo_img"
              />
            </div>
            <div className="Signup__title">파트너스 회원가입</div>

            <Box
              component="form"
              // onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
              ref={formRef}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="username"
                label="이름"
                type="text"
                id="username"
                autoComplete="current-username"
                // value={"홍길동"}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={"thebornsoft@gmail.com"}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={"00000000tb!"}
              />

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                회원가입
              </Button> */}

              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          <div className="Signup__bottom_btn_box">
            {/* <NavLink
              color="inherit"
              to={"/reset_password"}
              className="Login__signup__text"
            >
              Reset Password
            </NavLink> */}
            <br />
            <NavLink
              color="inherit"
              to={"/login"}
              className="Signup__login_link_text"
            >
              로그인 하러가기
            </NavLink>
          </div>
          <div className="Signup__bottom_btn_box">
            <br />
            <Button
              variant="outlined"
              onClick={() => handleClick({ typed: "mail_varify" })}
            >
              메일 인증
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleClick({ typed: "signup" })}
              disabled={!values.send_varify}
            >
              회원 가입
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleClick({ typed: "google_signup" })}
              // disabled={!values.send_varify}
            >
              구글로 회원가입
            </Button>
            <br />
            <br />
          </div>
          {/* <button onClick={() => handleSignout({ typed: "signup" })}>
            회원 탈퇴
          </button> */}
          {/* <button onClick={handleLogout}>Logout</button> */}
          {/* <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleAutoSignup}
          >
            Auto Signup (TEST)
          </Button> */}

          <Copyright sx={{ mt: 8, mb: 4 }} className="Copyright" />
        </Container>
      </ThemeProvider>
    </Styled.Signup>
  );
}

const Styled = {
  Signup: styled.div`
    .MuiButtonBase-root {
      /* padding: 13px 16px; */
    }
    .Signup__bottom_btn_box {
      text-align: center;
    }
    .Signup__login_link_text {
      text-decoration: none;
      color: black;
      letter-spacing: -0.5px;
      font-size: 14px;
    }

    .MuiFormControl-root {
      margin-top: 4px;
    }
    .Signup__logo {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      .Signup__logo_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .Signup__title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .Copyright {
      margin: 10px;
      a {
        font-size: 13px;
        letter-spacing: -0px;
      }
    }
    @media (hover: hover) {
      .Signup__login_link_text:hover {
        text-decoration: underline;
      }
    }
  `,
};

export default SignupContainer;
