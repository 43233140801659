import _ from "lodash-es";

//alert
export const ALERT_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  VALIDATION: "validation",
  WARNING: "warning",
};

export const NOTICE_CATEGORY = [
  {
    INDEX: "NONE",
    TITLE: "선택 안함",
  },
  {
    INDEX: "NOTICE",
    TITLE: "공지사항",
  },
  {
    INDEX: "NEWS",
    TITLE: "뉴스",
  },
];

export const COLUMN_RECOMMAND_TOPICS = [
  {
    INDEX: "BUSINESS",
    TITLE: "비즈니스",
  },
  {
    INDEX: "DEVELOPE",
    TITLE: "개발",
  },
  {
    INDEX: "MAKING",
    TITLE: "제작 사례",
  },
];
export const COLUMN_CATEGORY = [
  {
    INDEX: "NONE",
    TITLE: "태그 선택",
  },
  {
    INDEX: "ALL",
    TITLE: "전체",
  },
  {
    INDEX: "HOME_PAGE_PLANTER",
    TITLE: "홈페이지 기획",
  },

  {
    INDEX: "BUSINESS",
    TITLE: "비즈니스",
  },
  {
    INDEX: "MARKETING",
    TITLE: "마케팅",
  },
  {
    INDEX: "DESIGN",
    TITLE: "디자인",
  },
  {
    INDEX: "DEVELOPE",
    TITLE: "개발",
  },
  {
    INDEX: "TECHNOLOGY",
    TITLE: "기술",
  },
  {
    INDEX: "MAKING",
    TITLE: "제작 사례",
  },
];

export const PORTFOLIO_CATEGORY = [
  {
    INDEX: "NONE",
    TITLE: "선택 안함",
    match: "none",
  },
  {
    INDEX: "ALL",
    TITLE: "전체",
    match: "all",
  },
  {
    INDEX: "LANDING",
    TITLE: "랜딩페이지",
    match: "landing",
  },
  // {
  //   INDEX: "CONSULTING",
  //   TITLE: "개발 컨설팅",
  //   match: "consulting",
  // },
  {
    INDEX: "COMPANY",
    TITLE: "기업",
    match: "company",
  },
  {
    INDEX: "PUBLIC_GROUP",
    TITLE: "공공기관/단체/협회",
    match: "public_group",
  },
  {
    INDEX: "BRAND_EVENT",
    TITLE: "브랜드/이벤트",
    match: "brand_event",
  },
  {
    INDEX: "HOMEPAGE",
    TITLE: "홈페이지",
    match: "homepage",
  },
  // {`
  //   INDEX: "SHOP",
  //   TITLE: "쇼핑몰",
  //   match: "shop",
  // },`
  // {
  //   INDEX: "MVP",
  //   TITLE: "MVP개발",
  //   match: "mvp",
  // },
];

export const FAQ_CATEGORY = [
  {
    INDEX: "NONE",
    TITLE: "선택 안함",
    RANGE: 0,
  },
  {
    INDEX: "ALL",
    TITLE: "전체",
    RANGE: 100,
  },
  {
    INDEX: "READY",
    TITLE: "준비",
    RANGE: 1000,
  },
  {
    INDEX: "DEVELOPE",
    TITLE: "제작",
    RANGE: 2000,
  },
  {
    INDEX: "PRICE",
    TITLE: "결제",
    RANGE: 3000,
  },
  {
    INDEX: "REPAIRE",
    TITLE: "유지 관리·보수",
    RANGE: 4000,
  },
  {
    INDEX: "CONSULTING",
    TITLE: "상담",
    RANGE: 5000,
  },
  {
    INDEX: "ETC",
    TITLE: "일반",
    RANGE: 6000,
  },
  // {
  //   INDEX: "NEWS",
  //   TITLE: "유지보수",
  // },
];
export const DASHBOARD_TAGS = [
  {
    INDEX: "NONE",
    TITLE: "태그 선택",
  },
  {
    INDEX: "HOME_PAGE_PLANTER",
    TITLE: "홈페이지 기획",
  },
  {
    INDEX: "BUSINESS",
    TITLE: "비즈니스",
  },
  {
    INDEX: "MARKETING",
    TITLE: "마케팅",
  },
  {
    INDEX: "DEVELOPE",
    TITLE: "홈페이지 개발",
  },
];
// Page_loading
export const PAGE_LOADING = {
  NOT_LOADED: "not_loaded",
  LOAD_START: "load_start",
  LOAD_COMPLETE: "load_complete",
};

//  env
export const NODE_ENV_TYPE = {
  DEV: "development",
  PROD: "production",
  RELEASE: "release",
};

// portfolio nav list
export const PORTFOLIO_NAV_LIST = [
  {
    INDEX: "ALL",
    TITLE: "전체 프로젝트",
  },
  {
    INDEX: "RANDING",
    TITLE: "랜딩페이지",
  },
  {
    TITLE: "기업",
    INDEX: "COMPANY",
  },
  {
    TITLE: "공공기관·단체/협회",
    INDEX: "PUBLIC_GROUP",
  },
  {
    TITLE: "브랜드·이벤트",
    INDEX: "BRAND_EVENT",
  },
  {
    TITLE: "MVP 개발",
    INDEX: "MVP",
  },
  {
    TITLE: "쇼핑몰",
    INDEX: "CONSULTING",
  },
];

export const PHONE_PREFIX = [
  {
    INDEX: "010",
    TITLE: "010",
  },
  {
    INDEX: "011",
    TITLE: "011",
  },
  {
    INDEX: "016",
    TITLE: "016",
  },
  {
    INDEX: "017",
    TITLE: "017",
  },
  {
    INDEX: "018",
    TITLE: "018",
  },
  {
    INDEX: "019",
    TITLE: "019",
  },
];

export const MAIL_LIST = [
  { INDEX: "naver.com", VALUE: "네이버" },
  { INDEX: "gmail.com", VALUE: "지메일" },
  { INDEX: "hanmail.net", VALUE: "한메일" },
  { INDEX: "nate.com", VALUE: "네이트" },
  { INDEX: "empal.com", VALUE: "엠팔메일" },
  { INDEX: "netian.com", VALUE: "네띠앙" },
  { INDEX: "dreamwiz.co", VALUE: "드림위즈" },
  { INDEX: "lycos.co", VALUE: "라이코스" },
  { INDEX: "yahoo.co", VALUE: "야후한국" },
  { INDEX: "yahoo.com", VALUE: "야후미국" },
  { INDEX: "unitel.co", VALUE: "유니텔" },
  { INDEX: "chollian.ne", VALUE: "천리안" },
  { INDEX: "kornet.net", VALUE: "코넷" },
  { INDEX: "korea.com", VALUE: "코리아닷컴" },
  { INDEX: "freechal.co", VALUE: "프리첼" },
  { INDEX: "hananet.net", VALUE: "하나넷" },
  { INDEX: "hitel.net", VALUE: "하이텔" },
  { INDEX: "hanmir.com", VALUE: "한미르" },
  { INDEX: "hotmail.com", VALUE: "핫메일" },
  { INDEX: "", VALUE: "직접입력" },
];

// NOtE:
export const PRODUCT_ITEMS = {
  LANDING: {
    STANDARD: {
      SALE: "990,000",
      PRICE: "690,000",
    },
    DELUXE: {
      SALE: "2,390,000",
      PRICE: "1,690,000",
    },
    PREMIUM: {
      SALE: "7,990,000",
      PRICE: "5,990,000",
    },
  },
  CONSULTING: {
    // STANDARD: {
    //   SALE: "0",
    //   PRICE: "0",
    // },
    DELUXE: {
      SALE: "490,000",
      PRICE: "350,000",
    },
    PREMIUM: {
      SALE: "790,000",
      PRICE: "550,000",
    },
  },
};

// NOTE:문의하기 상품 리스트
export const CONSSULTANT_PRODUICT_TYPES = [
  {
    INDEX: "landing",
    TEXT: "랜딩페이지 제작",
  },
  {
    INDEX: "homepage",
    TEXT: "홈페이지 제작",
  },
  {
    INDEX: "service",
    TEXT: "웹서비스 제작",
  },
  {
    INDEX: "consulting",
    TEXT: "개발 컨설팅",
  },
  {
    INDEX: "repaire",
    TEXT: "유지보수",
  },
];

// NOTE:문의하기 가격
export const CONSSULTANT_PRODUICT_PRICE_TYPES = [
  {
    INDEX: "30-100",
    TEXT: "30~100만원",
  },
  {
    INDEX: "100-500",
    TEXT: "100~500만원",
  },
  {
    INDEX: "500-1000",
    TEXT: "500~1000만원",
  },
  {
    INDEX: "1000-3000",
    TEXT: "1000~3000만원",
  },
  {
    INDEX: "3000-",
    TEXT: "3000만원 이상",
  },
];

// NOTE:문의하기 상태
export const CONSOLTANT_STATUS = [
  {
    INDEX: "start",
    TEXT: "진행",
  },
  {
    INDEX: "end",
    TEXT: "완료",
  },
  {
    INDEX: "cancel",
    TEXT: "취소",
  },
  {
    INDEX: "noshow",
    TEXT: "노쇼",
  },
];

// NOTE: 들어오는 뷰에 따른 모드
export const WEBSITE_VIEW_MODE = {
  TALK: "talk",
};

// NOTE: 계약 CONTRACT 상태
export const CONTRACT_TYPES = {
  SUCCESS: {
    INDEX: "contract",
  },
  CANCEL: {
    INDEX: "cancel",
  },
  EXPIRE: {
    INDEX: "expire",
  },
};

// NOTE:문의하기 상태
export const CONTRACT_STATUS = [
  {
    INDEX: "create",
    TEXT: "생성",
  },
  {
    INDEX: "ing",
    TEXT: "진행",
  },
  {
    INDEX: "contract",
    TEXT: "완료",
  },
  {
    INDEX: "cancel",
    TEXT: "취소",
  },
  {
    INDEX: "expire",
    TEXT: "만료",
  },
  {
    INDEX: "end",
    TEXT: "종료",
  },
];
