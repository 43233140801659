import React, { useEffect } from "react";
import * as Containers from "containers";
import { Page } from "pages";
import { useSelector, useDispatch } from "react-redux";
import * as C from "utils/constants";
import * as Urls from "utils/urls";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

/**
 *
 * @returns
 */
function Login() {
  // const LoginState = {
  //   is_loaded: false,
  // };
  // const [values, setValues] = useImmer(LoginState);
  // const adminAuthReducer = useSelector((state) => state.auth);
  // const page_load = adminAuthReducer?.page_load;
  // const loadStatus = adminAuthReducer?.data?.login?.is_logged;
  // const is_loaded = loadStatus === C.PAGE_LOADING.LOAD_COMPLETE;
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // 로그인 되어있을시 redirect
  //   // console.log("몇번?");
  //   if (loadStatus === C.PAGE_LOADING.LOAD_COMPLETE) {
  //     // navigate(Urls.urls.admin.dashboard.index);
  //     // navigate(-1);
  //   } else {
  //   }
  // }, [loadStatus]);
  // console.group("login page");
  // console.log(loadStatus);
  // console.log(adminAuthReducer);
  // console.groupEnd();

  // if (is_loaded) {
  //   return "Loading...";
  // }

  // console.log(adminAuthReducer.data.login.is_logged);

  // console.log(page_load);
  // if (page_load === C.PAGE_LOADING.NOT_LOADED) {
  //   return "loading...";
  // }

  return (
    <Page meta={{ title: "Thebornsoft Partners" }}>
      <Containers.AuthContainer />
    </Page>
  );
}

export default Login;
