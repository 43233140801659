import React from "react";
import styled from "styled-components";
import { shared } from "styles/common";
import cx from "classnames";

/**
 *
 * @param {*} props
 * @returns
 */
function SectionWrapper(props) {
  const { bgColor = null, bgImage = null, className, style, id, dim } = props;
  return (
    <Styled.SectionWrapper
      id={id}
      className={cx("SectionWrapper", {
        [className]: className,
      })}
      style={{ backgroundColor: bgColor, backgroundImage: bgImage, ...style }}
    >
      {dim && (
        <div
          className="SectionWrapper__dim"
          style={{
            background: `rgba(0, 0, 0, ${dim})`,
          }}
        ></div>
      )}
      <div className="SectionWrapper__content">{props.children}</div>
    </Styled.SectionWrapper>
  );
}

const Styled = {
  SectionWrapper: styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    .SectionWrapper__dim {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      /* background: rgba(0, 0, 0, 0.6); */
    }
    .SectionWrapper__content {
      position: relative;
      z-index: 12;
      /* transform: scale(1); */
    }
    @media screen and (max-width: ${shared.device.pc630}) {
    }
  `,
};

export default React.memo(SectionWrapper);
