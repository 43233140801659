import React from "react";
import styled from "styled-components";
import { shared } from "styles/common";
import cx from "classnames";

/**
 *
 * @param {*} props
 * @returns
 */
function ContentWrapper(props) {
  const { small, mobilePaddingNone, size } = props;

  return (
    <Styled.ContentWrapper
      {...props}
      className={cx(`ContentWrapper`, props.className, {
        // small: small,
        mobilePaddingNone: mobilePaddingNone,
        [`-${size}`]: size,
      })}
    >
      {props.children}
    </Styled.ContentWrapper>
  );
}

const Styled = {
  ContentWrapper: styled.div`
    width: ${shared.device.pc1300};
    margin: 0 auto;

    &.-small {
      width: 800px;
    }
    &.-middle {
      width: 1400px;
    }
    &.-large {
      width: 1600px;
    }
    @media screen and (max-width: 1600px) {
      &.-middle {
        width: 95%;
      }
      &.-large {
        width: 90%;
      }
    }
    @media screen and (max-width: 1400px) {
      &.-middle {
        width: 90%;
      }
    }
    @media screen and (max-width: ${shared.device.pc1300}) {
      width: 90%;
      &.-small,
      &.-middle,
      &.-small,
      &.-large {
        width: 90%;
      }
    }

    @media screen and (max-width: ${shared.device.pc630}) {
      width: 100%;
      &:not(.mobilePaddingNone) {
        padding: 0 15px;
      }
      &.-small,
      &.-middle,
      &.-small,
      &.-big {
        width: 100%;
      }
    }
  `,
};

export default React.memo(ContentWrapper);
