import React, { useEffect } from "react";
import _ from "lodash-es";
import { useSelector, useDispatch } from "react-redux";
import * as Metas from "components/meta";
import { useLocation } from "react-router-dom";

/**
 *
 * @param {*} _props
 * @returns
 */

function Page(props) {
  const { title = "", meta = [], children, load, auth } = props;
  // const brandName = config.brand.brandName;
  const dispatch = useDispatch();
  const location = useLocation();

  const propsMeta = props.meta || {};
  return (
    <>
      <Metas.MetaTag
        description="개발사가 개발을 의뢰하는 곳. 홈페이지 제작의 장인. 홈페이지 제작 전문 기업 더본소프트."
        title="더본소프트"
        keywords={"더본소프트, 홈페이지, 제작, 웹사이트, 랜딩페이지, 개발, SEO"}
        // image={logo_png}
        url={location.pathname}
        {...propsMeta}
      />

      {children}
      {/* <Containers.MessageLayerContainer /> */}
    </>
  );
}

export default Page;
