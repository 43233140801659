import React, { useEffect } from "react";
import * as firebase from "utils/firebase.config";
import _ from "lodash-es";
import { v4 as uuid4 } from "uuid";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  deleteDoc,
  setDoc,
  updateDoc,
  doc,
  query,
  orderBy,
  startAfter,
  serverTimestamp,
  limit,
  startAt,
  endAt,
  endBefore,
  limitToLast,
  listDocuments,
  where,
} from "firebase/firestore";

// import { getDatabase, ref, push, set } from "firebase/database";
import {
  getStorage,
  deleteObject,
  uploadString,
  getDownloadURL,
  ref,
  push,
  set,
  put,
  uploadBytes,
} from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
  getRedirectResult,
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  sendEmailVerification,
  signInWithPopup,
  signOut,
} from "firebase/auth";

// SECTION: 파이어베이스 (Firebase)
/**
 *NOTE: firebase에 데이터 insert해주는 함수
 */
// export async function addFirebase(props) {
//   const {
//     collectionName = null, // collectionName
//     data,
//   } = props;
//   const { isError, origin } = await fetchData();
//   if (isError === false) {
//     return {
//       isError: false,
//       origin,
//     };
//   } else {
//     return {
//       isError: true,
//     };
//   }
//   async function fetchData() {
//     try {
//       const docRef = await addDoc(collection(firebase.db, collectionName), {
//         ...data,
//         created_date: serverTimestamp(),
//       });
//       console.log("Document written with ID: ", docRef.id);
//       // alert("접수되었습니다.");
//       return {
//         isError: false,
//         origin: docRef,
//       };
//     } catch (err) {
//       console.log(err);
//       // alert("잠시후 다시 시도해주세요.");
//       return {
//         isError: true,
//         error: err,
//       };
//     }
//   }
// }

// NOTE: 파이어베이스 회원가입
export class FirebaseSignup {
  constructor() {}
  EmailSignup(email, password) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        return {
          status: 200,
          ...userCredential,
        };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        return {
          status: null,
          ...error,
        };
      });
  }
}

// NOTE: 파이어베이스 회원가입
//동작이 이루어지면 앞서 작성한 로그인 상태 감지 함수로 인해 user정보가 변수에 저장되고 setState가 발생
export async function registerWithEamil(email, password) {
  try {
    const auth = getAuth();
    console.log(auth.currentUser);
    // if (!auth.currentUser.emailVerified) {
    //   alert("메일 인증이 필요합니다.");
    //   return;
    // }
    return await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        return {
          status: 200,
          isError: false,
          ...userCredential,
        };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        return {
          status: null,
          isError: true,
          ...error,
        };
      });
  } catch (e) {
    return e.message.replace("Firebase: Error ", "");
  }
}

// NOTE: 회원가입 이메일 인증
export async function joinWithVerification(email, password, typed) {
  const authMessage = {
    "auth/post-email-verification-mail": "인증 메일이 전송되었습니다..",
  };
  const errorMessage = {
    "auth/email-already-in-use":
      "이미 존재하는 이메일이거나, 메일 인증을 완료해주세요.",
    "auth/missing-email": "이메일을 확인해주세요.",
  };
  const auth = getAuth();

  console.log("시도");

  try {
    console.log(auth);
    console.log(auth, email, password);
    await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(auth.currentUser);

    // auth.signOut();
    console.log("auth.signOut");
    console.log(auth);
    alert(authMessage["auth/post-email-verification-mail"]);
    return {
      isError: false,
      user: auth.currentUser,
      origin: auth,
    };
  } catch ({ code, message }) {
    console.log("이메일 인증 오류");
    console.log(code, message);
    alert(errorMessage[code]);
    return {
      isError: true,
    };
  }
}

// NOTE:L 파이어베이스 Auth 유저 삭제
export async function deleteFirebaseUser(currentUser) {
  try {
    let auth = getAuth();
    let user = auth.currentUser;

    console.log(user);

    const result = await deleteUser(user);
    console.log(result);
    return {
      isError: false,
      status: 200,
      ...result,
    };
  } catch (e) {
    console.log("gmm..", e);
    return {
      isError: true,
      error: e.message.replace("Firebase: Error ", ""),
    };
  }
}

// NOTE: 더본소프트 Doc + 회원탈퇴

export async function signOutFirebaseUser(props = {}) {
  const { collectionName } = props;
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    console.log(user);

    let deleteDocError = true;

    const { isError, items } = await getGloSearchFirebaseDocs({
      collectionName: collectionName,
      keyword: user?.uid,
      indexName: "uidIndex",
      storeLimit: "ALL",
    });

    const getItem = items?.[0];

    console.log(getItem);
    console.log(isError);
    // if (!getItem) {
    //   return;
    // }

    if (getItem) {
      let { isError } = await deleteFirebaseDoc({
        collectionName: collectionName,
        documentId: getItem.document_id,
      });
      deleteDocError = isError;
    } else {
      deleteDocError = false;
    }

    if (deleteDocError === false) {
      const { isError } = await deleteFirebaseUser(user);

      console.log("deleteFirebaseUser");
      // console.log(_res);
      if (isError === false) {
        // navigate("/login");
        // alert("회원 탈퇴 완료");
        return {
          isError: false,
          status: 200,
        };
      } else {
        // alert("회원 탈퇴 실패");
        console.log("deleteFirebaseUser Auth 회원 탈퇴 실패");
        throw new Error();
      }
    } else {
      // alert("회원 탈퇴 실패");
      console.log("deleteFirebaseDoc document 회원 탈퇴 실패");
      throw new Error();
    }
  } catch (e) {
    return {
      isError: true,
      error: e.message.replace("Firebase: Error ", ""),
    };
  }
}

// NOTE: 파이어베이스 로그인
//Email로 로그인하는 함수
export async function loginWithEamil(datas) {
  const { email, password } = datas;
  try {
    const auth = getAuth();
    // const setPersist = await setPersistence(auth, browserSessionPersistence);
    // console.log(setPersist, "setPersist");
    const result = await signInWithEmailAndPassword(auth, email, password);
    console.log(result);
    return {
      isError: false,
      status: 200,
      ...result,
    };
  } catch (e) {
    return {
      isError: true,
      error: e.message.replace("Firebase: Error ", ""),
    };
  }
}

// NOTE: Google, Github로 회원가입하는 함수
// NOTE: 현재 구글만 됨
export async function signupWithSocial({ provider, collectionName }) {
  const signUpCollectionName = collectionName;
  const auth = getAuth();
  if (provider === "google") {
    try {
      const authProvider = new GoogleAuthProvider();

      return signInWithPopup(auth, authProvider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;

          console.log(credential);
          console.log(token);
          console.log(user);

          const { item } = await getSearchFirebaseDoc({
            collectionName: signUpCollectionName,
            keyword: user.email,
            searchIndex: "email",
          });

          if (item) {
            return {
              isError: false,
              message: "signup/already-email",
            };
          }

          const { isError: addDocError, origin } = await addFirebaseDoc({
            collectionName: signUpCollectionName,
            data: {
              email: user.email,
              emailVerified: user.emailVerified,
              isAnonymous: user.isAnonymous,
              signUpType: provider,
              uidIndex: [user?.uid],
              uid: user?.uid,
            },
          });

          if (addDocError !== false) {
            console.error("addFirebaseDoc 에러 in signupWithSocial");
            return;
          }

          console.log("origin");
          console.log(origin);

          const { isError: updateDocError } = await updateFirebaseDoc({
            collectionName: signUpCollectionName,
            documentId: origin.id,
            data: {
              document_id: origin.id,
            },
          });

          if (updateDocError === false) {
            return {
              isError: false,
              document_id: origin.id,
              ...result,
            };
          } else {
            console.error("updateFirebaseDoc 에러 in signupWithSocial");
          }

          console.log("origin");
          // console.log(origin.id);
          console.log(origin);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData?.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log(email);
          console.log(credential);
          return {
            isError: true,
          };
          // ...
        });
    } catch (error) {
      return {
        isError: true,
        error: error,
      };
    }
  }
}

// NOTE: Google, Github로 로그인하는 함수
export async function signinWithSocial({
  provider,
  collectionName,
  socialData,
}) {
  const auth = getAuth();
  console.log("signinWithSocial");
  if (provider === "google") {
    try {
      const providerType = new GoogleAuthProvider();

      return signInWithPopup(auth, providerType)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log(result);
          console.log(token);
          console.log(user);

          const { item } = await getSearchFirebaseDoc({
            collectionName: collectionName,
            keyword: user.email,
            searchIndex: "email",
          });

          if (!item) {
            const {
              isError: addDocError,
              error,
              origin,
            } = await addFirebaseDoc({
              collectionName: collectionName,
              data: {
                email: user.email,
                emailVerified: user.emailVerified,
                isAnonymous: user.isAnonymous,
                signUpType: provider,
                uidIndex: [user?.uid],
              },
            });
            if (addDocError !== false) {
              console.error("addFirebaseDoc 에러 in signupWithSocial");
              return;
            }

            console.log("origin");
            console.log(origin);

            const { isError: updateDocError } = await updateFirebaseDoc({
              collectionName: collectionName,
              documentId: origin.id,
              data: {
                document_id: origin.id,
              },
            });

            if (updateDocError === false) {
              return {
                isError: false,
                document_id: origin.id,
                ...result,
              };
            } else {
              console.error("updateFirebaseDoc 에러 in signupWithSocial");
            }
          }

          return {
            isError: false,
            ...result,
          };
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.log(email);
          console.log(credential);
          return {
            isError: true,
          };
          // ...
        });
    } catch (error) {
      return {
        isError: true,
        error: error,
      };
    }
  } else if (provider === "kakao") {
    try {
      // The signed-in user info.
      const user = socialData;
      console.log(user);

      const { item } = await getSearchFirebaseDoc({
        collectionName: collectionName,
        keyword: user.email,
        searchIndex: "email",
      });

      console.log("find item");
      console.log(item);
      if (!item) {
        console.log("in not iten");
        const {
          isError: addDocError,
          error,
          origin,
        } = await addFirebaseDoc({
          collectionName: collectionName,
          data: {
            email: user?.email,
            emailVerified: true,
            isAnonymous: false,
            signUpType: provider || "",
            // uidIndex: [user?.uid],
          },
        });
        if (addDocError !== false) {
          console.error("addFirebaseDoc 에러 in signupWithSocial", error);
          return {
            isError: true,
            error: error,
          };
        }

        const { isError: updateDocError } = await updateFirebaseDoc({
          collectionName: collectionName,
          documentId: origin.id,
          data: {
            document_id: origin.id,
            // uidIndex: [origin.id],
          },
        });

        const allFormData = {
          user: {
            email: user?.email,
            emailVerified: true,
            isAnonymous: false,
            signUpType: provider || "",
            document_id: origin.id,
            // uidIndex: [origin.id],
          },
        };

        if (updateDocError === false) {
          localStorage.setItem("loggedInfo", socialData.email);
          return {
            isError: false,
            document_id: origin.id,
            ...allFormData,
          };
        } else {
          console.error("updateFirebaseDoc 에러 in signupWithSocial");
        }
      }

      localStorage.setItem("loggedInfo", socialData.email);

      return {
        isError: false,
        user: {
          ...item,
        },
      };
    } catch (error) {
      return {
        isError: true,
        error: error,
      };
    }
  } else if (provider === "github") {
    try {
      const providerType = new GithubAuthProvider();

      await new signInWithRedirect(auth, providerType);
      const result = await getRedirectResult(auth);
      if (result) {
        // const user = result.user;
      }
      return {
        isError: false,
        status: 200,
        ...result,
      };
    } catch (error) {
      return {
        isError: true,
        error: error,
      };
    }
  }
}

// NOTE: 파이어베이스 로그아웃
export async function logout() {
  const auth = getAuth();
  try {
    const result = await signOut(auth);
    return {
      status: 200,
      ...result,
    };
  } catch (e) {
    return e.message.replace("Firebase: Error ", "");
  }
}

// NOTE: 로그인 유저가 이메일 확인 했는지?
export function getUserEmailVerified() {
  const auth = getAuth();
  const user = auth.currentUser;
  return !!user ? (user.emailVerified ? true : false) : false;
}

// NOTE: 로그인 유저 새로고침
export function refreshUser() {
  const auth = getAuth();
  let user = auth.currentUser;
  if (!!user && !user?.emailVerified) {
    let interval = setInterval(() => {
      user?.reload().then();
    }, 3000);
  }
}

// NOTE::사용자 정보가 변경되었는지 여부를 감지?
export function onUserChanged(listener) {
  const auth = getAuth();
  auth.onAuthStateChanged(listener);
}

// NOTE: 데이터 리스트
export async function getFirebaseDocs(props = {}) {
  const {
    column = "", // collectionName
    storeLimit = 10,
    isAll = null,
    setOderBy,
  } = props;

  const { items } = await readData();
  const isSuccess = _.isArray(items);

  if (isSuccess) {
    return {
      isError: false,
      items: items,
    };
  } else {
    return {
      isError: true,
      items: items,
    };
  }

  async function readData() {
    let getQuery = null;
    if (isAll) {
      if (setOderBy) {
        getQuery = query(
          collection(firebase.db, column),
          orderBy(setOderBy[0], setOderBy[1])
        );
      } else {
        getQuery = query(
          collection(firebase.db, column),
          orderBy("created_date", "desc")
        );
      }
    } else {
      getQuery = query(
        collection(firebase.db, column),
        orderBy("created_date", "desc"),
        limit(storeLimit)
      );
    }

    const querySnapshot = await getDocs(getQuery);

    const dataArray = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        origin: doc,
      };
    });

    return {
      items: dataArray,
    };
  }
}

// NOTE: 다음 데이터 리스트
export async function getPageFirebaseDocs(props = {}) {
  const {
    column = "", // collectionName
    storeLimit = 10,
    standItem,
    typed,
    page,
  } = props;

  console.log("in getPageFirebaseDocs");

  const { items } = await readData();
  const isSuccess = _.isArray(items);

  if (isSuccess) {
    return {
      isError: false,
      items: items,
    };
  } else {
    return {
      isError: true,
      items: items,
    };
  }

  async function readData() {
    let getDocsItems = null;
    if (typed === "page") {
      // DEBUG: 하.. 여기 어캐하냐
      console.log("page fire");
      console.log(page);
      getDocsItems = query(
        collection(firebase.db, column),
        orderBy("created_date", "desc"),
        startAt(page),
        limit(storeLimit)
      );
    }

    if (typed === "prev") {
      console.log("prev fire");
      getDocsItems = query(
        collection(firebase.db, column),
        orderBy("created_date", "desc"),
        endBefore(standItem),
        limitToLast(storeLimit)
      );
    }
    if (typed === "next") {
      console.log("next fire");
      getDocsItems = query(
        collection(firebase.db, column),
        orderBy("created_date", "desc"),
        startAfter(standItem),
        limit(storeLimit)
      );
    }

    // endBefore(firstVisible)
    // limitToLast

    const snapshots = await getDocs(getDocsItems);
    console.log("getDocsItems", snapshots);

    const dataArray = snapshots.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        origin: doc,
      };
    });

    return {
      items: dataArray,
    };
  }
}

// NOTE: // 이전 다음 데이터 1개씩
export async function getFirebasePrevNexdtBoardItems(props) {
  const {
    column, // collectionName
    boardId, // document ID
    setOderBy,
  } = props;
  // NOTE: 특정 데이터 가져오기

  try {
    const userRef = doc(firebase.db, column, boardId);
    const userSnap = await getDoc(userRef); // 데이터 스냅 받아오기 - 비동기처리
    let data = {};
    if (userSnap.exists()) {
      data = {
        origin: userSnap,
        data: userSnap.data(),
      };
    } else {
      data = null;
    }
    console.log("!");
    console.log(data);
    console.log(boardId);

    // NOTE: 특정으로부터 받아서 가져오기

    const standItem = data?.origin;
    console.log(standItem);

    const qprev = query(
      collection(firebase.db, column),
      // where("created_date", "<=", true)
      orderBy(setOderBy[0], "desc"),
      endBefore(standItem),
      limitToLast(1)
    );

    const qnext = query(
      collection(firebase.db, column),
      // where("created_date", "<=", true)
      orderBy(setOderBy[0], "desc"),
      startAfter(standItem),
      limit(1)
    );

    const queryPrevSnapshot = await getDocs(qprev);
    // console.log("*** prev");
    const prevData = [];
    queryPrevSnapshot?.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      prevData.push({
        origin: doc,
        data: doc.data(),
        id: doc.id,
      });
    });

    const queryNextSnapshot = await getDocs(qnext);
    // console.log("*** next");
    const nextData = [];
    queryNextSnapshot?.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      nextData.push({
        origin: doc,
        data: doc.data(),
        id: doc.id,
      });
    });

    return {
      isError: false,
      prevData,
      nextData,
    };
  } catch (err) {
    return {
      isError: true,
      error: err,
    };
  }
}

// NOTE: 서치 단일 데이터
// DEBUG: 수정 새롭게 만들어야함
export async function getSearchFirebaseDoc(props = {}) {
  // 여러 중복중 첫번째 것만 가져옴
  const {
    collectionName = "", // collectionName
    keyword = "",
    searchIndex = "",
  } = props;

  const { item } = await readData();
  // const isSuccess = _.isArray(item);

  if (item) {
    return {
      isError: false,
      item: item,
    };
  } else {
    return {
      isError: true,
      item: item,
    };
  }

  async function readData() {
    let q = null;
    q = query(
      collection(firebase.db, collectionName),
      where(searchIndex, ">=", keyword),
      where(searchIndex, "<=", keyword + "\uf8ff")

      // orderBy(searchIndex), // 제목 정렬
      // startAt(keyword),
      // endAt(keyword + "\uf8ff"),
    );

    const querySnapshot = await getDocs(q);

    console.log("readData");
    console.log(querySnapshot);
    console.log(querySnapshot.docs);

    const dataArray = querySnapshot.docs.map((doc) => {
      console.log(doc);
      return {
        id: doc.id,
        ...doc.data(),
        origin: doc,
      };
    });

    return {
      item: dataArray?.[0],
    };
  }
}

// NOTE: 서치 데이터들
export async function getSearchFirebaseDocs(props = {}) {
  const {
    column = "", // collectionName
    isAll,
    storeLimit = 10,
    keyword = "",
    cayegoryIndex = "1",
    indexArray,
    getWhere = "array",
    // getWhere = "string"
    setOderBy,
  } = props;

  const { items } = await readData();
  const isSuccess = _.isArray(items);

  if (isSuccess) {
    return {
      isError: false,
      items: items,
    };
  } else {
    return {
      isError: true,
      items: items,
    };
  }

  async function readData() {
    let indexObj = {
      ["1"]: "titleIndex",
      ["2"]: "authorIndex",
      // ['3']:'titleIndex',
    };
    if (indexArray) {
      indexObj = indexArray;
    }
    const searchIndex = indexObj[cayegoryIndex];

    let q = null;
    if (getWhere === "array") {
      if (isAll === true || storeLimit === "ALL") {
        q = query(
          collection(firebase.db, column),
          // orderBy("created_date", "desc"),
          where(searchIndex, "array-contains", keyword)
        );
      } else {
        q = query(
          collection(firebase.db, column),
          // where(searchIndex, "array-contains", keyword.toLowerCase()),
          where(searchIndex, "array-contains", keyword),

          // orderBy("created_date", "desc"),
          limit(storeLimit)
        );
      }
    }

    if (getWhere === "string") {
      q = query(
        collection(firebase.db, column),
        where(searchIndex, ">=", keyword),
        where(searchIndex, "<=", keyword + "\uf8ff")

        // orderBy(searchIndex), // 제목 정렬
        // startAt(keyword),
        // endAt(keyword + "\uf8ff"),
      );
    }

    const querySnapshot = await getDocs(q);

    const dataArray = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        origin: doc,
      };
    });

    return {
      items: dataArray,
    };
  }
}

// NOTE: 서치 데이터들 확장성
export async function getGloSearchFirebaseDocs(props = {}) {
  const {
    collectionName = "", // collectionName
    storeLimit = 10,
    keyword = "",
    indexName,
    orderBy: storeOrderBy,
    queryWhere = "array-contains",
  } = props;
  console.log("getGloSearchFirebaseDocs");
  console.log("storeOrderBy");
  console.log(props);
  // console.log(storeOrderBy);
  // console.log(storeOrderBy.join(","));

  const { items } = await readData();
  const isSuccess = _.isArray(items);

  if (isSuccess) {
    return {
      isError: false,
      items: items,
    };
  } else {
    return {
      isError: true,
      items: items,
    };
  }

  async function readData() {
    // where 확장
    const conditions = [
      { property: indexName, operator: queryWhere, value: keyword },
    ];

    let queryConditions = conditions.map((condition) => {
      return where(condition.property, condition.operator, condition.value);
    });

    // order 확장
    let orders = [{ property: indexName, value: keyword }];
    if (storeOrderBy) {
      orders = [{ property: storeOrderBy[0], value: storeOrderBy[1] }];
    }

    let queryOrders = orders.map((condition) => {
      return orderBy(condition.property, condition.value);
    });

    console.log(orders);
    let q = null;
    if (storeLimit === "ALL") {
      q = query(
        collection(firebase.db, collectionName),
        // where(indexName, ">=", keyword),
        // where(indexName, "<=", keyword + "\uf8ff")

        where(indexName, queryWhere, keyword)
        // orderBy("created_date", "desc")
        // ...queryConditions,
        // ...queryOrders
        // where(indexName, queryWhere, keyword)
      );
    } else {
      q = query(
        collection(firebase.db, collectionName),
        // where(searchIndex, "array-contains", keyword.toLowerCase()),
        where(indexName, queryWhere, keyword),

        orderBy("created_date", "desc"),
        limit(storeLimit)
      );
    }

    const querySnapshot = await getDocs(q);

    const dataArray = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        origin: doc,
      };
    });

    return {
      items: dataArray,
    };
  }
}

// NOTE: 서치 데이터 , 현재 안씀
// export async function getFirebaseSearchDocs(props = {}) {
//   const {
//     column = "",
//     isAll,
//     storeLimit = 10,
//     indexArray,
//     getWhere = "array",
//     // getWhere = "string"
//     setOderBy,
//     keyword = "",
//     categoryKeyword,
//     cayegoryIndex,
//     searchIndex,
//   } = props;

//   const { items } = await readData();
//   const isSuccess = _.isArray(items);

//   if (isSuccess) {
//     return {
//       isError: false,
//       items: items,
//     };
//   } else {
//     return {
//       isError: true,
//       items: items,
//     };
//   }

//   async function readData() {
//     let q = null;
//     if (getWhere === "array") {
//       if (isAll === true) {
//         q = query(
//           collection(firebase.db, column),
//           // orderBy("created_date", "desc"),
//           where(searchIndex, "array-contains", keyword)
//         );
//       } else {
//         q = query(
//           collection(firebase.db, column),
//           // where(searchIndex, "array-contains", keyword.toLowerCase()),
//           where(cayegoryIndex, "array-contains", categoryKeyword),
//           where(searchIndex, "array-contains", keyword),
//           orderBy(setOderBy, "desc"),
//           // orderBy("created_date", "desc"),
//           limit(storeLimit)
//         );
//       }
//     }

//     if (getWhere === "string") {
//       q = query(
//         collection(firebase.db, column),
//         where(searchIndex, ">=", keyword),
//         where(searchIndex, "<=", keyword + "\uf8ff")
//       );
//     }

//     const querySnapshot = await getDocs(q);

//     const dataArray = querySnapshot.docs.map((doc) => {
//       return {
//         id: doc.id,
//         ...doc.data(),
//         origin: doc,
//       };
//     });

//     return {
//       items: dataArray,
//     };
//   }
// }
// NOTE:
export async function getColumnCategoryNextBoard(props) {
  const {
    column = "",
    isAll,
    storeLimit = 10,
    keyword = "",
    cayegoryIndex = "1",
    indexArray,
    getWhere = "array",
    // getWhere = "string"
    setOderBy,
    standItem,
    searchIndex,
  } = props;

  console.log(searchIndex);
  let q = null;

  // DEBUG:
  console.log("standItem");
  console.log(standItem.data());
  const qnext = query(
    collection(firebase.db, column),
    // where("created_date", "<=", true)
    // where('created_date', "<=", keyword),
    where(searchIndex, "array-contains", keyword),
    orderBy(setOderBy, "desc"),
    startAfter(standItem),
    limit(2)
  );
  const queryNextSnapshot = await getDocs(qnext);
  console.log("*** next");
  const nextData = [];
  queryNextSnapshot?.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    nextData.push({
      origin: doc,
      data: doc.data(),
      id: doc.id,
    });
  });
  console.log(nextData);

  return {
    isError: false,
    items: nextData,
  };
}

// NOTE: 컬럼 개수별로 다음거 가져오는거
// 현재 안씀
// export async function getColumnNextItems(props) {
//   const {
//     column = "",
//     keyword = "",
//     setOderBy,
//     standItem,
//     searchIndex,
//   } = props;

//   console.log(searchIndex);
//   let q = null;

//   // DEBUG:
//   console.log("standItem");
//   console.log(standItem.data());
//   const qnext = query(
//     collection(firebase.db, column),
//     // where("created_date", "<=", true)
//     // where('created_date', "<=", keyword),
//     where(searchIndex, "array-contains", keyword),
//     orderBy(setOderBy, "desc"),
//     startAfter(standItem),
//     limit(2)
//   );
//   const queryNextSnapshot = await getDocs(qnext);
//   console.log("*** next");
//   const nextData = [];
//   queryNextSnapshot?.forEach((doc) => {
//     // console.log(doc.id, " => ", doc.data());
//     nextData.push({
//       origin: doc,
//       data: doc.data(),
//       id: doc.id,
//     });
//   });
//   console.log(nextData);

//   return {
//     isError: false,
//     items: nextData,
//   };
// }

// NOTE: 스토리지 업로드
export async function uploadStorageFirebaseDoc(props) {
  const { fileUrl, userId = "thebornsoft" } = props;
  // fileUrl =file
  // userId ==> storage 주소
  console.log(fileUrl);

  const storage = getStorage();
  const storageRef = ref(storage, `${userId}/${uuid4()}`);
  try {
    const uploadResult = await uploadBytes(storageRef, fileUrl).then(
      async (snapshot) => {
        const url = await getDownloadURL(snapshot.ref);
        return url;
      }
    );

    return {
      isError: false,
      data: uploadResult, // 업로드  url
    };

    // return await getDownloadURL(storageRef);
  } catch (err) {
    return {
      isError: true,
      err: err,
    };
    throw new Error(err);
  }
}

// NOTE: 스토리지 삭제
export async function deletetorageFirebaseDoc(props) {
  const { deleteStorageSrc } = props;

  // Create a reference to the file to delete
  const storage = getStorage();
  const desertRef = ref(storage, deleteStorageSrc);
  // const desertRef = ref(storage, "images/desert.jpg");

  // const storageRef = ref(storage, `${userId}/${uuid4()}`);
  try {
    // Delete the file
    const deleteResult = await deleteObject(desertRef)
      .then((res) => {
        // File deleted successfully
        console.log(res);
        console.log("success");
        return res;
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        throw new Error(error);
      });
    console.log(deleteResult);

    // const uploadResult = await uploadBytes(storageRef, fileUrl).then(
    //   async (snapshot) => {
    //     const url = await getDownloadURL(snapshot.ref);
    //     return url;
    //   }
    // );

    return {
      isError: false,
      data: deleteResult,
    };

    // return await getDownloadURL(storageRef);
  } catch (err) {
    return {
      isError: true,
      err: err,
    };
    throw new Error(err);
  }
}

/**
 *NOTE: firebase에 데이터 insert해주는 함수
 */
export async function addFirebaseDoc(props) {
  const { collectionName = null, data } = props;
  const { isError, origin, error } = await fetchData();
  console.log(collectionName);
  console.log(data);
  if (!isError) {
    return {
      isError: false,
      origin,
    };
  } else {
    return {
      isError: true,
      origin,
      error: error,
    };
  }
  async function fetchData() {
    try {
      const docRef = await addDoc(collection(firebase.db, collectionName), {
        ...data,
        created_date: serverTimestamp(),
      });
      console.log("Document written with ID: ", docRef.id);
      // alert("접수되었습니다.");
      return {
        isError: false,
        origin: docRef,
      };
    } catch (err) {
      console.log(err);
      // alert("잠시후 다시 시도해주세요.");
      return {
        isError: true,
        error: err,
      };
    }
  }
}

// NOTE: 데이터 도큐먼트 보기
export async function getFirebaseDoc(props = {}) {
  const {
    collectionName = "", // collectionName
    documentId, // document ID
  } = props;
  // document => id

  const { isError, item } = await readData();
  if (!isError) {
    return {
      isError: false,
      item: item,
    };
  } else {
    return {
      isError: true,
      // data: getData,
    };
  }
  //
  async function readData() {
    const docRef = doc(firebase.db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        isError: false,
        item: {
          ...docSnap.data(),
          origin: docSnap,
          id: docSnap.id,
        },
      };
    } else {
      console.log("Document does not exist");
      return {
        isError: true,
        // item:getData
      };
    }
    return;

    // const querySnapshot = await getDocs(collection(firebase.db, column));

    // const dataArray = querySnapshot.docs.map((doc) => {
    //   return {
    //     id: doc.id,
    //     ...doc.data(),
    //   };
    // });
    // // console.log(dataArray);
    // getData = dataArray;
  }

  // console.log(getData, "1");
}

// NOTE: 데이터 수정
export async function updateFirebaseDoc(props) {
  const {
    collectionName = null, // collectionName
    data,
    documentId, // document ID
  } = props;
  const { isError, item, error } = await fetchData();
  if (isError === false) {
    return {
      isError: false,
      item: item,
    };
  } else {
    return {
      isError: true,
      error: error,
    };
  }
  async function fetchData() {
    try {
      const washingtonRef = doc(firebase.db, collectionName, documentId);
      const setData = {
        ...data,
        updated_date: serverTimestamp(),
      };
      const docRef = await updateDoc(washingtonRef, setData);

      // console.log("Document written with ID: ", docRef?.id);
      // console.log(docRef);
      console.log(setData);

      return {
        isError: false,
        item: setData,
        origin: docRef,
      };
    } catch (err) {
      console.log(err);
      // alert("잠시후 다시 시도해주세요.");
      return {
        isError: true,
        error: err,
      };
    }
  }
}

// NOTE: 도큐먼트 삭제
export async function deleteFirebaseDoc(props) {
  const {
    collectionName = "", // collectionName
    documentId, // document ID
  } = props;
  let isSuccess = true;
  let errorObj = {};

  try {
    await deleteDoc(doc(firebase.db, collectionName, documentId));
  } catch (err) {
    console.log("errer deleteFirebaseDoc");
    console.log(err);
    errorObj = err;
    isSuccess = false;
  }
  if (isSuccess) {
    return {
      isError: false,
    };
  } else {
    return {
      isError: true,
      error: errorObj,
    };
  }
}

// DEBUG:: 도큐먼트 여러개 삭제
// export async function deleteFirebaseDocs(props) {
//   const { column = "", documents } = props;
//   let isSuccess = true;
//   let errorObj = {};

//   documents.map(documentId=>{
//     deleteDoc(doc(firebase.db, column, documentId));
//   })

//   Promise.all([])
//   const documentId = document;
//   try {
//     await deleteDoc(doc(firebase.db, column, documentId));
//   } catch (err) {
//     console.log("errer deleteFirebaseDoc");
//     console.log(err);
//     errorObj = err;
//     isSuccess = false;
//   }
//   if (isSuccess) {
//     return {
//       isError: false,
//     };
//   } else {
//     return {
//       isError: true,
//       error: errorObj,
//     };
//   }
// }

// NOTE: 컬럼 추천
// export async function setRecommendColumn(props) {
//   const { column = "", document } = props;
//   let isSuccess = true;
//   let errorObj = {};
//   try {
//     const docRef = await addDoc(collection(firebase.db, column), {
//       ...data,
//       created_date: serverTimestamp(),
//     });
//     getFirebaseDocs;
//   } catch (err) {
//     console.log("errer deleteFirebaseDoc");
//     console.log(err);
//     errorObj = err;
//     isSuccess = false;
//   }
//   if (isSuccess) {
//     return {
//       isError: false,
//     };
//   } else {
//     return {
//       isError: true,
//       error: errorObj,
//     };
//   }
// }
