import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import styled from "styled-components";
import { shared } from "styles/common";

/**
 *
 * @returns
 */
export default function ProfileMenu(props) {
  const { onLogout, onSetting, onDashboard, email } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Styled.ProfileMenu>
      <div onClick={handleClick}>
        <div className="ProfileMenu__profile_box">
          {email[0]?.toUpperCase()}
        </div>
        {/* <Avatar style={{ margin: "auto" }} /> */}
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "& .MuiMenuItem-root": {
              fontSize: "15px",
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            ".ProfileMenu__email": {
              fontSize: "14px",
              color: "rgb(99, 115, 129);",
            },
            ".ProfileMenu__main_url": {
              fontSize: "14px",
              color: "black",
              fontWeight: "bold",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={onDashboard}>
          <div>
            <div className="ProfileMenu__main_url">대시보드</div>
            <div className="ProfileMenu__email">{email}</div>
          </div>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={onSetting}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={onSetting}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          세팅
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <div>로그아웃</div>
        </MenuItem>
      </Menu>
    </Styled.ProfileMenu>
  );
}

const Styled = {
  ProfileMenu: styled.div`
    .ProfileMenu__profile_box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      font-family: "Public Sans", sans-serif;
      font-size: 1.25rem;
      line-height: 1;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      color: rgb(255, 255, 255);
      background-color: rgb(255, 86, 48);
      background-color: rgb(77 168 142);
      font-weight: 600;
      margin: auto;
    }
    .ProfileMenu__email {
      ${shared.mixins.fonts()};
      color: rgb(99, 115, 129);
    }
  `,
};
