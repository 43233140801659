import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";
import Button from "@mui/material/Button";

/**
 *
 * @param {*} props
 * @returns
 */

function SettingContainer(props) {
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  const handleSignout = () => {
    const isConfirm = window.confirm(
      "탈퇴 시 1달 간 재가입이 불가능하며,\n더본소프트의 모든 혜택이 사라지게 됩니다.\n정말 회원을 탈퇴하시겠습니까?"
    );
    if (isConfirm) {
      Firebases.signOutFirebaseUser({
        collectionName: "partners_users",
      }).then((res) => {
        if (res?.isError === false) {
          alert("그 동안 더본소프트를 사랑해주셔서 감사합니다.");
          navigate("/login");
        } else {
          alert("회원 탈퇴 실패, 재 로그인 후 시도해주세요.");
        }
      });
    }

    // Firebases.deleteFirebaseUser().then((_res) => {
    //   console.log("deleteFirebaseUser");
    //   console.log(_res);
    //   if (_res?.isError === false) {
    //     navigate("/login");
    //   }
    // });
  };

  return (
    <>
      <Page>
        <SettingMain onSignout={handleSignout} />
      </Page>
    </>
  );
}

function SettingMain(props) {
  const { onSignout } = props;
  return (
    <>
      <div>세팅</div>

      <a
        href="/"
        onClick={(event) => {
          if (onSignout) {
            event.preventDefault();
            onSignout();
          } else {
          }
        }}
      >
        <Button variant="outlined">회원탈퇴</Button>
      </a>
    </>
  );
}

export default React.memo(SettingContainer);
