import { css } from "styled-components";

function fonts(config = {}) {
  const {
    color = "black",
    size = "15px",
    weight = "normal",
    letterSpace = "-0.5px",
  } = config;

  return `
    color: ${color};
    font-size: ${size};
    font-weight: ${weight};
    letter-spacing: ${letterSpace};
    line-height: 1.3;
    /*  font-family: 'spoqa','Play','Nanum Gothic',  sans-serif; */
    font-family: "Play", "Nanum Gothic", sans-serif;
  `;
}
export const mixins = (props) => {
  const { colors, styles, device } = props;
  return {
    floatClear: `
      &:after {
        display: block;
        content: "";
        clear: both;
      }
    `,
    positionCenterCenter: `
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `,
    masterBadge: `
      align-self: center;
      padding: 0 10px;
      border-radius: 9px;
      background-color: rgba(231, 131, 9, 0.2);
      font-size: 10px;
      font-weight: bold;
      color: #faa628;
      text-align: center;
      line-height: 18px;
    `,
    ThemeBlackScrollBar: `
      &::-webkit-scrollbar {
        width: 9px; /* 스크롤바의 너비 */
      }
      &::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: #4d5766; /* 스크롤바의 색상 */
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1); /*스크롤바 뒷 배경 색상*/
      }
    `,
    ThemeGrayScrollBar: css`
      &::-webkit-scrollbar {
        width: 9px; /* 스크롤바의 너비 */
      }
      &::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: #cacaca; /* 스크롤바의 색상 */
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(163, 163, 163, 0.1); /*스크롤바 뒷 배경 색상*/
      }
    `,
    fonts: fonts,
    dotdotdot: (lineClamp) => {
      return `
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
      `;
    },
    tickerAnimation: (theme = "white") => {
      let animationAttr = "animation: smoothlight-white 1s forwards;";
      if (theme === "green") {
        animationAttr = `animation: smoothlight-green 1s forwards;`;
      }
      if (theme === "red") {
        animationAttr = `animation: smoothlight-red 1s forwards;`;
      }
      return `
      ${animationAttr};

      `;
    },
    lineBar: (config = {}) => {
      const {
        backgroundColor = colors.main_red,
        height = "2px",
        width = "40px",
        margin = "40px auto",
      } = config;
      return `
        background: ${backgroundColor};
        height: ${height};
        width: ${width};
        margin: ${margin};
      `;
    },
    bannerTitle: (config = {}) => {
      return `
        position: relative;
        z-index: 10;
        ${fonts()};
        font-size: 35px;
        /* // font-size:27px; */
        font-weight: bold;
        letter-spacing: -2px;
        margin: 50px 0;
        text-align: center;
        &:after {
          position: absolute;
          content: "";
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          width: 40px;
          height: 3px;
          background: #c51f1f;
          bottom: -25px;
        }
      `;
    },

    serviceDetailProduction630(shared) {
      return `
        .ServiceDetailProductSection__container {
          padding: 40px 0;
        }
        .ServiceDetailProductSection__title {
          font-size: ${shared.sizes.mobile.banner.title.fontSize};
          letter-spacing: ${shared.sizes.mobile.banner.title.letterSpacing};
          margin-bottom: 20px;
        }
        .ServiceDetailProductSection__product_title {
          font-size: 22px;
        }
        .ServiceDetailProductSection__product_info {
          font-size: 18px;
          font-size: ${shared.sizes.mobile.banner.label.fontSize};
          margin-bottom: 0;
        }
        .ServiceDetailProductSection__product_image_box {
          margin-bottom: 30px;
          width: 40px;
          height: 40px;
        }
        .ServiceDetailProductSection__product_box {
          flex-direction: column;
          gap: 0;
        }
        .ServiceDetailProductSection__product_item {
          position: relative;
          width: 100%;
          padding: 25px 0;
          /* margin: 20px 0; */
          &:last-child {
            &:after {
              display: none;
            }
          }
          &:after {
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background: black;
            opacity: 0.1;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .ServiceDetailProductSection__product_link_box {
          margin-top: 0px;
        }
        .ServiceDetailProductSection__product_icon {
          font-size: 14px;
        }
        .ServiceDetailProductSection__product_bar {
          margin: 25px auto;
        }
        .ServiceDetailProductSection__product_link {
          margin-bottom: 0;
          &.f_support {
            margin-bottom: 0;
            font-size: ${shared.sizes.mobile.banner.text.fontSize};
          }
        }
      `;
    },
    serviceBanner630(shared, $prefix_name) {
      return `
        .${$prefix_name}__box {
          flex-direction: column;
          gap: 20px 0;
        }
        .${$prefix_name}__item {
          width: 100%;
        }
        .${$prefix_name}__item {
          /* text-align: center; */
          &.f_info {
            margin-bottom: 20px;
          }
          &.f_image {
            text-align: center;
          }
        }
        .${$prefix_name}__title_label {
          font-size: ${shared.sizes.mobile.banner.label.fontSize};
          margin-bottom: 10px;
        }
        .${$prefix_name}__title {
          font-size: ${shared.sizes.mobile.banner.title.fontSize};
        }
        .${$prefix_name}__subtitle {
          font-size: ${shared.sizes.mobile.banner.subtitle.fontSize};
          margin-bottom: 40px;
        }
        .${$prefix_name}__container {
          padding: 80px 0;
        }
        .${$prefix_name}__img {
          width: 70%;
        }
      `;
    },
    serviceTitle(shared) {
      return `
        ${shared.mixins.fonts({
          size: "75px",
        })};
        font-size: 65px;
        font-weight: bold;
        letter-spacing: -2px;
        line-height: 1.3;
        position: relative;
        margin-bottom: 40px;

        &::before {
          content: "";
          position: absolute;
          display: block;
          top: -18px;
          left: auto;
          width: 14px;
          height: 14px;
          background: #ce171f;
          border-radius: 100%;
        }
        &.--right_dot {
          &:before {
            right: 10px;
          }
        }
        &.--left_dot {
          &:before {
            left: 10px;
          }
        }
      `;
    },
    selectBoxBasic() {
      return `
        border: 1px solid #dbdbdb;
        padding: 5px;
        cursor: pointer;
      `;
    },
  };
};
