import { handleActions } from "redux-actions";
import produce from "immer";
import $ from "jquery";
import * as F from "utils/fns";
import * as C from "utils/constants";
import * as Libs from "utils/lib";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import _ from "lodash-es";
import * as Firebases from "utils/firebase";

// SECTION:
const initialState = {
  page_load: C.PAGE_LOADING.NOT_LOADED,
  data: {
    is_logged: null,
    // 페이지단위
    user_info: {
      email: null,
      username: null,
      single_id: null,
      level: null,
      department: null,
      level_id: null,
      country: null,
      use_flag: null,
    },
    login: {
      is_logged: C.PAGE_LOADING.NOT_LOADED,
    },
    meta_data: {
      post_tag_items: null,
    },
  },
};

export const LOAD_PAGE = "admin/auth/LOAD_PAGE";
export const LOAD_META_DATA = "admin/auth/LOAD_META_DATA";
export const LOAD_USER = "admin/auth/LOAD_USER";
export const LOGOUT_USER = "admin/auth/LOGOUT_USER";
export const LOGIN_PAGE_LOADING = "admin/auth/LOGIN_PAGE_LOADING";
export const CHANGE_LOGO = "admin/auth/CHANGE_LOGO";

// NOTE: 로딩바만 풀어주는 load함수
export const loadController = (datas) => (dispatch) => {
  const { type, load } = datas;
  dispatch({ type: type, load: load });
};

// NOTE: 전체 앱 페이지 로드
export const pageLoad = (status) => (dispatch) => {
  // const { type, load } = datas;

  console.log(status);
  if (status) {
    dispatch(
      loadController({
        type: LOAD_PAGE,
        load: C.PAGE_LOADING.LOAD_COMPLETE,
      })
    );
  } else {
    dispatch(
      loadController({
        type: LOAD_PAGE,
        load: C.PAGE_LOADING.NOT_LOADED,
      })
    );
  }
};

// NOTE: 로그인 시도
export const login = (datas) => async (dispatch) => {
  const submitForm = {
    email: datas.email,
    password: datas.password,
    provider: datas?.provider || "email",
    user: datas?.user, // provider가 email이 아닐때만 들어옴
  };

  console.log(submitForm);
  console.log(submitForm.user?.accessToken);

  const signInCollectionName = "partners_users";
  if (submitForm?.provider !== "email") {
    // NOTE: provider 로그인
    console.log("일로옴");

    const { item } = await Firebases.getSearchFirebaseDoc({
      collectionName: signInCollectionName,
      keyword: submitForm.email,
      searchIndex: "email",
    });
    console.log(item);
    if (item) {
      await dispatch({
        type: LOAD_USER,
        data: {
          ...item,
        },
      });
      await dispatch(setLocalStorageLoggedInfo(submitForm.user?.accessToken));
      return F.promiseReturn({
        isError: false,
        status: 200,
      });
    } else {
      return F.promiseReturn({
        isError: true,
      });
    }
  } else {
    // NOTE: 일반 로그인
    console.log("로그인 시도");
    dispatch(setLocalStorageLoggedInfo(null, true));
    // firebase 로그인 진행
    const result = await Firebases.loginWithEamil(submitForm);

    if (result?.user && result.isError === false) {
      //로그인 성공
      console.log("login in");
      console.log(result);

      let getUserData = {};
      // 우리 디비의 유저정보 가져옴
      const getResult = await Firebases.getGloSearchFirebaseDocs({
        collectionName: signInCollectionName,
        storeLimit: "ALL",
        keyword: result?.user?.uid,
        indexName: "uidIndex",
      });

      console.log(getResult);
      if (_.isArray(getResult?.items)) {
        getUserData = getResult?.items[0];
        // console.log(getUserData);
      }

      await dispatch({
        type: LOAD_USER,
        data: {
          ...getUserData,
          ...result?.user,
        },
      });
      await dispatch(setLocalStorageLoggedInfo(result.user.accessToken));
      // await dispatch(
      //   loadController({
      //     type: LOGIN_PAGE_LOADING,
      //     load: C.PAGE_LOADING.LOAD_COMPLETE,
      //   })
      // );
      return F.promiseReturn({
        isError: false,
        status: 200,
      });
    } else {
      // dispatch(
      //   loadController({
      //     type: LOGIN_PAGE_LOADING,
      //     load: C.PAGE_LOADING.LOAD_COMPLETE,
      //   })
      // );
      return F.promiseReturn({
        isError: true,
        status: null,
      });
    }
  }
};

// NOTE: 로그아웃 시도
export const logout = (datas) => async (dispatch) => {
  // firebase 로그인 진행
  const result = await Firebases.logout();
  const auth = getAuth();
  auth.signOut();
  localStorage.removeItem("loggedInfo");

  if (result.status === 200) {
    await dispatch({
      type: LOGOUT_USER,
    });
    return F.promiseReturn({
      status: result.status,
    });
  } else {
    return F.promiseReturn({
      status: null,
    });
  }
};

// NOTE: 데이터 정보 세팅
export const setLocalStorageLoggedInfo =
  (accessToken, isDelete) => async (dispatch) => {
    console.log("setLocalStorageLoggedInfo");
    if (isDelete) {
      localStorage.removeItem("loggedInfo");
    } else {
      localStorage.setItem("loggedInfo", accessToken);
    }

    return;
  };

// NOTE: 로그인 살아있는지 체크
// 토큰을 검증하는게 아니라, 그냥 localstorage가 있는지만 봄
export const isSessionAlive = (datas) => async (dispatch) => {
  const getLocalstorage = localStorage.getItem("loggedInfo");
  const auth = getAuth();
  console.log("isSessionAlive");

  if (getLocalstorage) {
    try {
      return await auth.onAuthStateChanged(async (user) => {
        console.log("login in check");

        let hasSession = false;
        let status = 200;

        console.log(user);
        if (user && user?.email) {
          // 우리 디비의 유저정보 가져옴
          const result = await Firebases.getSearchFirebaseDoc({
            collectionName: "partners_users",
            keyword: user?.email,
            searchIndex: "email",
          });

          let getUserData = result?.item;

          console.log("getUserData");
          console.log(getUserData);

          await dispatch({
            type: LOAD_USER,
            data: { ...getUserData, ...user },
          });
          hasSession = true;

          status = 200;
        } else {
          status = 200;
        }

        await dispatch(
          loadController({
            type: LOAD_PAGE,
            load: C.PAGE_LOADING.LOAD_COMPLETE,
          })
        );

        if (status !== 200) {
          console.log("서버 에러");
          return F.promiseReturn({
            status: 500,
            hasSession: false,
          });
        } else {
          console.log("세션", hasSession);
          return F.promiseReturn({
            status: status,
            hasSession: !!hasSession,
          });
        }
      });
    } catch (err) {
      console.log(err);
      return F.promiseReturn({
        status: 500,
        isError: true,
        hasSession: false,
      });
    }
  } else {
    console.log("로그인 필요, 데이터없음");
    await dispatch(
      loadController({
        type: LOAD_PAGE,
        load: C.PAGE_LOADING.LOAD_COMPLETE,
      })
    );
    return F.promiseReturn({
      status: 200,
      // isError: false,
      hasSession: false,
    });
  }
};

export default handleActions(
  {
    [LOAD_PAGE]: (state, data) => {
      return produce(state, (draft) => {
        // console.log("LOAD_PAGE !");
        // console.log(data.load);
        draft.page_load = data.load;
        // console.log(load);
      });
    },
    [LOGIN_PAGE_LOADING]: (state, data) => {
      return produce(state, (draft) => {
        console.log("LOGIN_PAGE_LOADING !");
        // console.log(data);
        draft.data.login = {
          is_logged: data.load,
        };
      });
    },
    [LOAD_USER]: (state, { data }) => {
      return produce(state, (draft) => {
        console.log("LOAD_USER");
        // console.log(data);

        // console.log(data);

        // user_name={userInfo?.username}
        // user_level={userInfo?.user_level}
        // user_job={userInfo?.user_job}
        // user_profile={userInfo?.user_image}

        console.log(data?.emailVerified);
        console.log(data);
        draft.data.user_info = {
          id: data?.document_id,
          email: data.email,
          ...data,
          // email_verified: data?.emailVerified,
          // username: data.username,
          // user_job: data.user_job,
          // user_image: data.user_image,
          // user_level: data.user_level,
        };
      });
    },
    [LOGOUT_USER]: (state, { data }) => {
      return produce(state, (draft) => {
        console.log("LOGOUT_USER");
        draft.data.user_info = initialState.data.user_info;
        draft.data.login.is_logged = initialState.data.login.is_logged;
        // console.log(load);
      });
    },
  },
  initialState
);
