import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";

/**
 *
 * @param {*} props
 * @returns
 */
function HeaderContainer(props) {
  const { typed = "dashboard" } = props;
  const location = useLocation();
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  const user_info = {
    email: AuthReducer?.data.user_info?.email,
    email_verified: AuthReducer?.data.user_info?.email_verified,
  };
  // console.log(AuthReducer?.data.user_info?.email_verified);

  // NOTE:
  const handleLogout = () => {
    dispatch(AuthActions.logout()).then((res) => {
      if (location.pathname !== "/") {
        navigate("/login");
      }
      // dispatch(AuthActions.pageLoad(true));
    });
  };

  return (
    <>
      {/* <Wrappers.ContentWrapper> */}
      {typed === "main" && (
        <Headers.MainHeader
          isLogged={isLogged}
          onLogout={handleLogout}
          // onSignout={handleSignout}
          user_info={user_info}
        />
      )}
      {typed === "dashboard" && (
        <Headers.DashboardHeader
          isLogged={isLogged}
          onLogout={handleLogout}
          // onSignout={handleSignout}
          user_info={user_info}
        />
      )}

      {/* </Wrappers.ContentWrapper> */}
    </>
  );
}

export default HeaderContainer;
