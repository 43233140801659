import { useRef, useContext, useEffect, useState, useCallback } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useNavigate,
  Navigate,
  Outlet,
} from "react-router-dom";

import $ from "jquery";
import { useImmer } from "use-immer";
import axios from "axios";
import * as F from "utils/fns";
import { useSelector, useDispatch } from "react-redux";

// NOTE: class형 updatemount 라이프사이클
export const useUpdateEffect = (effect, deps) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (!isFirstMount.current) effect();
    else isFirstMount.current = false;
  }, deps);
};

// NOTE: 로그인 규격화
export const useAuthenticated = ({
  isNotAuthenticatedRedirect = "/login",
  baseAction,
  hasLoggedUserInfo,
  isPageLoad: BasePageLoad,
}) => {
  const isDispatchLogged = BasePageLoad; // 이건 맨 처음에만 진행 하는 것임
  const isAuthenticated = hasLoggedUserInfo;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogged, setPageLoad] = useState(null);

  useEffect(() => {
    //로그인 체크해서 있으면 로그인하고 없으면 페이지만 로드
    console.log("useEffect isSessionAlive");
    dispatch(baseAction());
  }, []);

  useEffect(() => {
    // console.group();
    // // console.log("accessToken", !!accessToken);
    // // console.log("refreshToken", refreshToken);
    // console.log("isAuthenticated", isAuthenticated);
    // console.log("isLogged", isLogged);
    // console.groupEnd();

    if (isDispatchLogged) {
      if (isAuthenticated) {
        setPageLoad(true);
      } else {
        // console.log("?");
        setPageLoad(true);
        // navigate(isNotAuthenticatedRedirect);
      }
    }
  }, [isDispatchLogged, isAuthenticated, isLogged]);

  return [isAuthenticated, isLogged];
};
// NOTE: useAuthenticated의 원본
// const dispatch = useDispatch();
// const navigate = useNavigate();
// const AuthReducer = useSelector((state) => state.auth);
// const accessToken = !!localStorage.getItem("loggedInfo");
// const refreshToken = isAuthComplete;

// useEffect(() => {
//   //로그인 체크해서 있으면 로그인하고 없으면 페이지만 로드
//   console.log("useEffect isSessionAlive");
//   dispatch(actions.isSessionAlive());
// }, []);
// Hooks.useUpdateEffect(() => {
//   if (isLogged) {
//     // console.log(!!accessToken, isAuthComplete, isLogged);
//     if (isAuthComplete) {
//       dispatch(AuthActions.pageLoad(true));
//     } else {
//       // dispatch(AuthActions.pageLoad(true));
//       dispatch(AuthActions.pageLoad(true));
//       navigate("/login");
//     }
//   }
// }, [isAuthComplete, isLogged]);
