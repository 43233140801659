import { handleActions } from "redux-actions";
import produce from "immer";

import * as C from "utils/constants";

import _ from "lodash-es";

export const LOAD_META_DATA = "base/LOAD_META_DATA";
export const LOAD_USER_INFO = "base/LOAD_USER_INFO";
export const LOAD_NOTIFICATIONS = "base/LOAD_NOTIFICATIONS";
export const IS_NOT_LOGGED = "base/IS_NOT_LOGGED";
export const DESTROY_SESSION = "base/DESTROY_SESSION";
export const UPDATE_META = "base/UPDATE_META";
export const GET_RECOMMEND_COLUMNS_META = "base/GET_RECOMMEND_COLUMNS_META";
export const LOAD_SERVICE_META = "base/LOAD_SERVICE_META";

const initialState = {
  load: C.PAGE_LOADING.NOT_LOADED,
  data: {
    is_logged: null,
    financial: {
      stream_list: [],
    },
    user_info: {
      username: null,
      single_id: null,
      level: null,
      department: null,
      level_id: null,
      country: null,
      use_flag: null,
    },
    meta_data: {
      post_tag_items: null,
      recommend_columns_items: [],
      service_column_items: [],
    },
  },
};

export default handleActions(
  {
    [LOAD_META_DATA]: (state, { load }) =>
      produce(state, (draft) => {
        try {
          draft.load = load;
        } catch (e) {
          console.log(e);
        }
      }),
  },
  initialState
);
