import { combineReducers } from "redux";
import base from "./base";
import auth from "./auth";
// import home from "./home";
// import faq from "./faq";
// import notice from "./notice";
// import column from "./column";
// import portfolio from "./portfolio";
// import adminBase from "./admin/base";
// import adminDashboard from "./admin/dashboard";
// import adminService from "./admin/service";
// import adminAuth from "./admin/auth";
// import adminNotice from "./admin/notice";
// import adminQna from "./admin/qna";
// import adminFAQ from "./admin/faq";
// import adminPortfolio from "./admin/portfolio";
// import adminColumn from "./admin/column";
// import base from "./base";

export default combineReducers({
  base,
  auth,
  // home,
  // faq,
  // column,
  // portfolio,
  // notice,
  // adminBase,
  // adminDashboard,
  // adminAuth,
  // adminNotice,
  // adminQna,
  // adminFAQ,
  // adminPortfolio,
  // adminColumn,
  // adminService,
  // base,
});
