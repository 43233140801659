import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";
import * as Navs from "components/nav";

/**
 *
 * @param {*} props
 * @returns
 */
function DashboardNavContainer(props) {
  const { typed = "dashboard" } = props;
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  const user_info = {
    email: AuthReducer?.data.user_info?.email,
    email_verified: AuthReducer?.data.user_info?.email_verified,
  };
  console.log(AuthReducer);

  return (
    <>
      {/* <Wrappers.ContentWrapper> */}

      {typed === "dashboard" && (
        <Navs.DashboardNav
          isLogged={isLogged}
          // onLogout={handleLogout}
          // onSignout={handleSignout}
          email={user_info?.email}
        />
      )}
    </>
  );
}

export default DashboardNavContainer;
