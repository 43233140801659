// NOTE: 맨앞글자를 크게해줌
export function capialize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// NOTE: 렌덤 아이템
export function mkItems(len = 5, fn) {
  return Array(len).fill(true).map(fn);
}

//NOTE: throttle 구현
export const throttle = function (callback, waitTime) {
  let timerId = null;
  return (e) => {
    if (timerId) return;
    timerId = setTimeout(() => {
      callback.call(this, e);
      timerId = null;
    }, waitTime);
  };
};

/**
 * NOTE: url을 파싱하여 object로 return 해주는 함수
 * @param {*} string
 * @returns
 */
export function getUrlStringParser(string) {
  try {
    if (typeof string !== "string") {
      return null;
    } else if (string.trim().length === 0) {
      return null;
    }
    let search = string.substring(1);

    return JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );

    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * getUrlObjectOrValue() 그냥 이렇게쓰면 모두가져오고
 * getUrlObjectOrValue('orderId') 이렇게쓰면 값 1개만 가져옴
 * @param {*} key
 * @returns
 */
export function getUrlObjectOrValue(key) {
  var valueObject = {},
    hash,
    value;

  window.location.href
    .split("?")
    .slice(1)
    .join("=")
    .split("&")
    .forEach((item) => {
      const stinrURl = item;

      const splitString = stinrURl.split("=");
      const keyname = splitString[0];

      if (keyname) {
        valueObject[keyname] = stinrURl.substring(stinrURl.indexOf("=") + 1);
      }
    });

  // var hashes = window.location.href
  //   .slice(window.location.href.indexOf("?") + 1)
  //   .split("&");
  // for (var i = 0; i < hashes.length; i++) {
  //   hash = hashes[i].split("=");
  //   valueObject[hash[0]] = hash[1];
  // }

  // if (key) {
  //   if (valueObject[key]) {
  //     return valueObject[key];
  //   }

  //   return "";
  // }

  if (key) {
    if (valueObject[key]) {
      return valueObject[key];
    }
  } else {
    return valueObject;
  }
}

/**
 * NOTE: object를 파싱하여 url paremeter string 으로 변경
 * @param {*} obj
 * @returns
 */
export function objectToParameterString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

// NOTE: then 리턴 콜백
export const promiseReturn = (result) =>
  new Promise((resolve) => resolve(result));

export const replaceUrl = (props) => {
  const { url } = props;
  window.history.pushState("", "", url);
};

// NOTE: 이미지 섬네일 업로드
/**
 * 
 * 
 *    encodeFileToBase64(e.target.files[0], function (reader) {
        setValues((draft) => {
          draft.sumbnailImage = reader.result;
        });
      });
    }
<div className="preview">
  {values.sumbnailImage && <img src={values.sumbnailImage} alt="preview-img" />}
</div>;


 * @param {*} fileBlob 
 * @param {*} callback 
 * @returns 
 */
export const encodeFileToBase64 = (fileBlob, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise((resolve) => {
    reader.onload = () => {
      // setImageSrc(reader.result);
      callback(reader);

      resolve();
    };
  });
};

// NOTE: 이미지 원본 팝업
export function fnImgPop(url) {
  var img = new Image();
  img.src = url;
  var img_width = img.width;
  var win_width = img.width + 25;
  var img_height = img.height;
  var win = img.height + 30;
  var OpenWindow = window.open(
    "",
    "_blank",
    "width=" +
      img_width +
      ", height=" +
      img_height +
      ", menubars=no, scrollbars=auto"
  );
  OpenWindow.document.write(
    "<style>body{margin:0px;}</style><img src='" +
      url +
      "' width='" +
      win_width +
      "'>"
  );
}

/**
 * 
 * 함수는 아니고 특정엘리먼트 맨아래로 스크롤 내리는 코드
 * 
  // NOTE:
  const handleClick = (config) => {
    const { typed } = config;
    // 인원 추가
    if (typed === "addTeam") {
      setValues((draft) => {
        draft.joinTeam.push({
          position: null,
          names: [],
        });
      });
    }
  };

  useEffect(() => {
    teamBoxRef.current.scrollTo({ top: teamBoxRef.current.scrollHeight });
  }, [values.joinTeam.length]);

 * 
 */

// NOTE: html에서 글자만 빼내줌
export const convertPureTextInTag = ({ html }) => {
  return html.replace(/<[^>]*(>|$)| |‌|»|«|>/g, " ").replace(/&/g, "&");
};

export const isEmpty = function (value) {
  if (
    value == "" ||
    value == null ||
    value == undefined ||
    (value != null && typeof value == "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};

// NOTE: gps 현재 위치 가져오기
// gps 허용을 해야 확인됨
export function getLocation() {
  let lat, long;
  if (navigator.geolocation) {
    // GPS를 지원하면
    navigator.geolocation.getCurrentPosition(
      function (position) {
        lat = position.coords.latitude;
        long = position.coords.longitude;
        alert("위도 : " + lat + " 경도 : " + long);
      },
      function (error) {
        console.error(error);
      },
      {
        enableHighAccuracy: false,
        maximumAge: 0,
        timeout: Infinity,
      }
    );
  } else {
    alert("GPS를 지원하지 않습니다");
    return;
  }
}

// NOTE: 폰넘버 리플레이싱
export function toPhoneNumber(value, type) {
  let formatNum;

  // 숫자 아닌 것 모두 제거
  let num = value.replace(/\D/g, "");

  if (num.length > 11) {
    num = num.substring(0, num.length - 1);
  }

  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    if (num.indexOf("02") === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3");
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3");
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
    }
  }

  return formatNum;
}

// NOTE: 특수문자 들어갔으면 true
export function characterCheck(obj) {
  var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;
  // 허용할 특수문자는 여기서 삭제하면 됨
  // 지금은 띄어쓰기도 특수문자 처리됨 참고하셈
  if (regExp.test(obj.value)) {
    obj.value = obj.value.substring(0, obj.value.length - 1); // 입력한 특수문자 한자리 지움
    return true;
  }
}

// NOTE: number comma
export const numberWithCommas = (x) => {
  if (isNaN(x)) {
    return "-";
  } else {
    return typeof x === "number"
      ? String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : x;
  }
};

// NOTE: 파일 base64로 file[0] 넣어야함
/** const getLogiFile = logoRef.current.files[0];
 * const base64Src= await F.toBase64(getLogiFile);
 * @param {*} file
 * @returns
 */
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
