import styled, { css } from "styled-components";

export const globalcss = css`
  [data-sticky] {
    position: sticky;
    top: 0;
    z-index: 100;
  }
  .bold {
    font-weight: bold;
  }

  .spnew {
    display: inline-block;
    color: transparent;
  }
  .blind {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    color: transparent;
  }
  body {
    &.theme--black {
      background: #0b0b0b;

      &::-webkit-scrollbar {
        /* 스크롤바의 너비 */
        width: 9px;
        /* height: 30px;  */
      }
      &::-webkit-scrollbar-thumb {
        /* height: 10%;  */
        /* 스크롤바의 길이 */
        background: #4d5766;
        /* 스크롤바의 색상 */
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(33, 122, 244, 0.1);
        /*스크롤바 뒷 배경 색상*/
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #434c5a;
        /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */
      }

      &::-webkit-scrollbar-thumb:active {
        background: #3e4653;
        /* 실질적 스크롤 바를 클릭할 때 */
      }
    }
  }

  @keyframes smoothlight-white {
    0% {
      background: #ffffff64;
      color: white;
    }
    100% {
      background: transparent;
    }
  }
  @keyframes smoothlight-green {
    0% {
      background: #16c78365;
      color: white;
    }
    100% {
      background: transparent;
    }
  }
  @keyframes smoothlight-red {
    0% {
      background: #ea394271;
      color: white;
    }
    100% {
      background: transparent;
    }
  }

  @keyframes hoverArrowRightAni {
    0% {
      right: 0;
    }
    50% {
      right: -10px;
    }
    100% {
      right: 0;
    }
  }
  .scroll-lock {
    overflow: hidden;
  }
  .Forendon__hr {
    border-bottom: 1px solid #dbdbdb7d;
    height: 1px;
    width: 100%;
  }
  .bold {
    font-weight: bold;
  }
  .display-none {
    display: none !important;
  }
  * {
    box-sizing: border-box;
  }

  @keyframes hoverArrowLeftAni {
    0% {
      left: 0;
    }
    50% {
      left: -10px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes hoverArrowRightAni {
    0% {
      right: 0;
    }
    50% {
      right: -10px;
    }
    100% {
      right: 0;
    }
  }

  @media screen and (max-width: 630px) {
    .m-max-630-none {
      display: none;
      margin-right: 10px;
    }
  }
`;
