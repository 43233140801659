export const adminUrlsPrefix = "/_tbsmaster";

export const routers = {
  main: "/",
  home: {
    index: "/home",
  },
  auth: {
    index: "/auth",
    signup: "signup",
    login: "login",
  },
  about: {
    index: "/about",
    company_news: "company_news",
    work_process: "work_process",
    difference: "difference",
    introduction: "introduction",
  },
  service: {
    index: "/service",
    landing: "landing",
    mvp: "mvp",
    company: "company",
    consulting: "consulting",
    public_group: "public_group",
    brand_event: "brand_event",
    homepage: "homepage",
    detail: ":categoryId",
  },
  consultant: {
    index: "/consultant",
  },
  faq: {
    index: "/faq",
  },
  column: {
    index: "/column",
    detail: "detail/:boardId",
    search: "search",
  },
  portfolio: {
    index: "/portfolio",
    category: "category",
    detail: ":boardId",
  },
  contract: {
    index: "/contract",
    result: "result",
  },
  policy: {
    index: "/policy",
    // privacy: ":typed",
    // detail: ":typed",
    privacy: "privacy",
    denine_email: "denine_email",
    refund: "refund",
    compensate: "compensate",
  },
  support: {
    index: "/support",
    faq: "faq",
    consultant: "consultant",
    pay: "pay",
    pay_result: "pay/result/:orderId/:amount/:paymentKey", // 이거안됨
    qna_result: "qna_result",
  },
  pay_result: {
    index: "/support/pay/result",
    result: ":orderId/:amount/:paymentKey",
  },
};

export const urls = {
  main: {
    home: "/",
  },
  home: {
    index: "/",
  },

  policy: {
    index: "/policy",
    privacy: "/policy/privacy",
    denine_email: "/policy/denine_email",
    refund: "/policy/refund",
    compensate: "/policy/compensate",

    // privacy: "/policy?typed=privacy",
    // denine_email: "/policy?typed=denine_email",
  },
  support: {
    consultant: "/support/consultant",
    faq: "/support/faq",
    pay: "/support/pay",
    pay_result: "/support/pay/result",
    qna_result: "/support/qna_result",

    consulting_qna: (product_type) => {
      if (product_type) {
        return `/support/consultant?product_type=${product_type}`;
      } else {
        return `/support/consultant`;
      }
    },
  },
  portfolio: {
    index: "/portfolio",
    landing: "/portfolio/category?type=landing",
    homepage: "/portfolio/category?type=homepage",
    company: "/portfolio/category?type=company",
    public_group: "/portfolio/category?type=public_group",
    brand_event: "/portfolio/category?type=brand_event",
    mvp: "/portfolio/category?type=mvp",
    consulting: "/portfolio/category?type=consulting",
    shop: "/portfolio/category?type=shop",
    detail: ({ boardId }) => `/portfolio/${boardId}`,
  },
  column: {
    index: "/column",
    list: "/column",
    detail: (columnId) => `/column/detail/${columnId}`,
    search: (category, search) => {
      if (search) {
        return `/column?typed=${category}&search=${search}`;
      } else {
        return `/column?typed=${category}`;
      }
    },
  },
  service: {
    index: "/service",
    landing: "/service/landing",
    company: "/service/company",
    public_group: "/service/public_group",
    brand_event: "/service/brand_event",
    mvp: "/service/mvp",
    consulting: "/service/consulting",
    homepage: "/service/homepage",
  },
  about: {
    index: "/about",
    company_news: "/about/company_news",
    work_process: "/about/work_process",
    difference: "/about/difference",
    introduction: "/about/introduction",
  },
  sns: {
    blog: "https://blog.naver.com/thebornsoft",
    instagram: "https://www.instagram.com/thebornsoft",
    facebook: "https://www.facebook.com/thebornsoft/",
    kakaotalk: "http://pf.kakao.com/_xfxjCxjxj",
    twitter: "https://twitter.com/thebornsoft",
  },
  contract: {
    index: "/contract",
    result: "/contract/result",
  },
};
