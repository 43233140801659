import config from "./config";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
// import { initializeAdminApp } from "firebase-admin/app"
// // var serviceAccount = require("path/to/serviceAccountKey.json");
// export const admin = initializeAdminApp({
//   credential: admin.credential.cert(
//     process.env.REACT_APP_FIREBAS_ADMIN_SERVICE_ACCOUNT
//   ),
//   databaseURL:
//     "https://thebornsoft-default-rtdb.asia-southeast1.firebasedatabase.app",
// });

export const app = initializeApp(config.firebase);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

// if (process.env.NODE_ENV === "development") {

// }
