import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Urls from "utils/urls";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";
import styled from "styled-components";
import { shared } from "styles/common";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

/**
 *
 * @param {*} props
 * @returns
 */

function FooterContainer(props) {
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  return (
    <>
      <Page>
        <FooterMain />
      </Page>
    </>
  );
}

function FooterMain(props) {
  return (
    <Styled.FooterMain>
      <div className="FooterMain__container">
        <Wrappers.ContentWrapper>
          <div className="FooterMain__box">
            <div className="FooterMain__rows t_logo">
              <div className="FooterMain__logo">
                THEBORNSOFT <br />
                PARTNERS
              </div>
            </div>

            <div className="FooterMain__rows t_info">
              <div className="FooterMain__infomation_box">
                더본소프트 대표 : 이준영 <br />
                사업자 등록번호 : 771-36-00964 통신판매업신고 :
                2022-고양덕양구-2445 <br />
                경기도 고양시 덕양구 고양대로 1393 2층 <br />
                문의하기 : thebornsoft@gmail.com <br />
              </div>
            </div>
            <div className="FooterMain__rows t_policy">
              <div className="FooterMain__policy_box">개인정보 처리방침</div>
            </div>
            <div className="FooterMain__rows t_sns">
              <div className=" FooterMain_sns_box">
                <div className="FooterMain_text_label">SNS</div>
                <a
                  href={Urls.urls.sns.facebook}
                  className="FooterMain_link_icon_box"
                  target={"_blank"}
                  alt="페이스북 팝업열기"
                >
                  f
                </a>
                <a
                  href={Urls.urls.sns.blog}
                  className="FooterMain_link_icon_box"
                  target={"_blank"}
                  alt="블로그 팝업열기"
                >
                  B{/* <FontAwesomeIcon className="" icon={faBlog} /> */}
                </a>
                <a
                  href={Urls.urls.sns.twitter}
                  className="FooterMain_link_icon_box"
                  target={"_blank"}
                  alt="트위터"
                >
                  <TwitterIcon alt="트위터 아이콘" />
                </a>
                <a
                  href={Urls.urls.sns.instagram}
                  className="FooterMain_link_icon_box"
                  target={"_blank"}
                  alt="인스타그램 팝업열기"
                >
                  <InstagramIcon alt="인스타그램 아이콘" />
                </a>
              </div>
            </div>
            <div className="FooterMain__rows t_copyright">
              <div className="FooterMain__copyright">
                COPYRIGHT@ 2022 THEBORN SOFT. ALL RIGHT RESERVED.
              </div>
            </div>
          </div>
        </Wrappers.ContentWrapper>
      </div>
    </Styled.FooterMain>
  );
}

const Styled = {
  FooterMain: styled.footer`
    .FooterMain__container {
      background: #191f28;
      padding: 65px 0;

      .FooterMain__box {
        display: flex;
        flex-direction: column;
      }
      .FooterMain__rows {
        &.t_logo {
        }
        &.t_info {
          margin-top: 40px;
        }
        &.t_policy {
          margin-top: 40px;
        }
        &.t_sns {
          margin-top: 20px;
        }
        &.t_copyright {
          margin-top: 15px;
        }
      }
      .FooterMain__logo {
        ${shared.mixins.fonts()};
        color: white;
        font-size: 24px;
        font-weight: bold;
      }
      .FooterMain__infomation_box {
        ${shared.mixins.fonts()};
        font-size: 15px;
        color: #8b95a1;
      }
      .FooterMain__copyright {
        ${shared.mixins.fonts()};
        font-size: 15px;
        color: #8b95a1;
      }
      .FooterMain__policy_box {
        ${shared.mixins.fonts()};
        font-size: 15px;
        color: #8b95a1;
        font-weight: bold;
        text-decoration: underline;
      }
      .FooterMain_text_label {
        ${shared.mixins.fonts({
          size: "16px",
          color: shared.colors.text_gray,
        })};
        font-weight: bold;
        margin-right: 10px;
      }
      .FooterMain_sns_box {
        display: flex;
        margin: 5px 0;
        align-items: center;
        gap: 0 10px;
      }
      .FooterMain_link_icon_box {
        display: flex;
        width: 40px;
        height: 40px;
        background: black;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 21px;
        color: white;
        text-decoration: none;
      }

      @media screen and (max-width: ${shared.device.pc630}) {
      }

      @media (hover: hover) {
        .FooterMain_link_icon_box:hover {
          opacity: 0.8;
        }
      }
    }
  `,
};

export default React.memo(FooterContainer);
