import * as C from "utils/constants";

const REACT_ENV = process.env;

console.log(C?.NODE_ENV_TYPE);
export const NODE_ENV = process.env.REACT_APP_NODE_ENV || C.NODE_ENV_TYPE.DEV;
export const IS_PROD_NODE_ENV = NODE_ENV === C.NODE_ENV_TYPE.PROD;
export const IS_DEV_NODE_ENV = NODE_ENV === C.NODE_ENV_TYPE.DEV;
export const IS_RELEASE_NODE_ENV = NODE_ENV === C.NODE_ENV_TYPE.RELEASE;

export default {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  },

  server: {
    apiAddress: "",
  },
  brand: {
    brandName: "더본소프트",
    brandNameKo: "더본소프트",
    brandNameEn: "thebornsoft",
  },
  portals: {
    modal: "app-modal",
    notify: "app-notify",
    brand_layer: "app-brand-layer",
    loading_layer: "app-loading-layer",
  },
  lang: {
    match: {
      startPage: "page:startPage",
      select_language_text: "page:select_language_text",
      stream_nav_list: {
        home: "page:stream_nav_list.home",
        explore: "page:stream_nav_list.explore",
        notifications: "page:stream_nav_list.notifications",
        messages: "page:stream_nav_list.messages",
        bookmarks: "page:stream_nav_list.bookmarks",
        profile: "page:stream_nav_list.profile",
        trending: "page:stream_nav_list.trending",
        more: "page:stream_nav_list.more",
      },
      language: {
        ko: "page:language.ko",
        en: "page:language.en",
      },
    },
  },
  basic: {
    default_user_image: `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1CvVWfyeNhk-5RPUuO8dXUOFXAz7LyLSVZA&usqp=CAU`,
  },
  links: {
    more_question_link: `http://pf.kakao.com/_xfxjCxjxj`,
    homepage_dev_qna_link: "https://forms.gle/ZnRrFUwFENm8HEZm6",
    repair_qna_link: "https://forms.gle/1piRmoSAL2K8z197A",
    consulting_link: `https://forms.gle/LujMphuXEebfpk4t5`,
    product_deatil_qna_link: "https://forms.gle/UpFDYxxkDSPRiPsx8",
    service_introduce_link:
      "https://firebasestorage.googleapis.com/v0/b/thebornsoft.appspot.com/o/thebornsoft%2Fintroduction%2FTHEBORN_SOFT_INTRODUCTION.pdf?alt=media&amp;token=1709781e-dd76-402e-a525-5ec0b7b9660a",
    recruit:
      "https://orchid-iron-b98.notion.site/517ceeaf319b45a69a2a5bbbc51861d2",
    service: {
      free_consulting: {
        main: "https://forms.gle/1vYMY5sFySRzL3S68",
      },
      homepage: {
        main: "https://forms.gle/ZnRrFUwFENm8HEZm6",
      },
      landing: {
        main: "https://forms.gle/xFu8VCEGGTihtGWj7",
        standard: `https://forms.gle/gYgw8dkx99GFEZ4L7`,
        deluxe: `https://forms.gle/fs5fRpo2mskr97Y89`,
        premium: `https://forms.gle/DtqTGNXZqeBY9VUy9`,
      },
      consulting: {
        main: "https://forms.gle/91uCREjDEYCxZyi87",
      },
      repair: {
        main: "https://forms.gle/23Kzz7jftiS6gNHG7",
      },
    },
  },
  column_names: {
    column: "admin_column",
    dashboard: "admin_dashboard",
    faq: "admin_faq",
    notice: "admin_notice",
    portfolio: "admin_portfolio",
    service: "admin_service",
  },
  column_rows_count_num: 3,
  localStorage: {
    recentViewItemKeyName: "thebornViewSite",
  },
  maps: {
    naver_client_id: process.env.REACT_APP_NAVER_MAP_ID,
    kakao_client_id: process.env.REACT_APP_KAKAO_MAP_ID,
  },

  env: {
    CLIENT_ADDRESS: IS_PROD_NODE_ENV
      ? process.env.REACT_APP_SITE_URL
      : process.env.REACT_APP_DEV_SITE_URL,
  },
  business_info: {
    company_name: "더본소프트",
    ceo_name: "이준영",
    address: "경기도 고양시 덕양구 고양대로 1393 2층 (상상빌딩)",
    qna_email: "thebornsoft@gmail.com",
    copyright_title: "COPYRIGHT@ 2022 THEBORN SOFT. ALL RIGHT RESERVED.",
    business_registration_number: "771-36-00964",
    fax: "0508-957-2132",
    communication_sel_number: "2022-고양덕양구-2445",
    contact_number: "0507-1310-2132",
  },
};

// 릴리즈일때만 없애야함 원래는
if (IS_RELEASE_NODE_ENV) {
  console.log = () => {};
}
// 배포 속도 테스트 해보기
if (IS_PROD_NODE_ENV) {
  console.log = () => {};
}
