import React from "react";
import styled, { css } from "styled-components";

import cx from "classnames";
import { styled as m_styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
/**
 *
 * @param {*} props
 * @returns
 */
export function FullScreenLoader(props) {
  const {
    setid = "",
    defaultClass = "FullScreenLoader",
    defaultHide = false,
    classNames,
    opacity = 1,
    theme = "",
  } = props;
  return (
    <Styled.FullScreenLoader setid={setid} className={classNames}>
      <div
        className={cx(defaultClass, theme, {
          FullScreenLoader__hide: defaultHide,
        })}
      >
        <div
          className="full-screen-dim"
          style={{
            opacity: opacity,
          }}
        ></div>
        <div className="custom-loader">
          <CustomizedProgressBars />
          {/* <img
            src={circle_loader}
            width="30"
            className="FullScreenLoader-loader-img"
          /> */}
        </div>
      </div>
    </Styled.FullScreenLoader>
  );
}

const BorderLinearProgress = m_styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ff1a1a" : "#e83030",
  },
}));

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#f20707" : "#ff2525",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

function CustomizedProgressBars() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FacebookCircularProgress />
      {/* <br /> */}
      {/* <BorderLinearProgress variant="determinate" value={50} /> */}
    </Box>
  );
}

const Styled = {
  FullScreenLoader: styled.div`
    .FullScreenLoader {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 500000;
      /* background: white; */
      &.deepWhite {
        .full-screen-dim {
          background: white;
        }
      }
      &.FullScreenLoader__hide {
        display: none;
      }
      .full-screen-dim {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: white;
        z-index: 500001;
      }
      .custom-loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: black;
        z-index: 500002;
        /* .FullScreenLoader-loader-img {
          animation: circle infinite linear 1.5s;
        } */
      }
    }
    /* 
    ${(props) =>
      "#" + props.setid &&
      css`{
       
      .full-screen-dim {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        z-index: 500000;

      }
      .custom-loader {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: black;
          animation: circle infinite linear 1.5s;
        }
      .FullScreenLoader__hide {
        display: none;
      }
    `} */

    @keyframes circle {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
  BasicLoader: styled.div`
    .BasicLoader__box {
      position: relative;
      min-height: 80px;
    }
    .BasicLoader__box_custom-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: black;
    }
    .BasicLoader__box_custom-loader-img {
      animation: circle infinite ease-out 0.8s;
    }
    @keyframes circle {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
};
