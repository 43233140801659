import React, { useEffect } from "react";
import * as Containers from "containers";
import { Page } from "pages";
import { useSelector, useDispatch } from "react-redux";
import * as C from "utils/constants";
import * as Urls from "utils/urls";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

/**
 *
 * @returns
 */
function ResetPassword() {
  return (
    <Page
      title="ResetPassword"
      meta={[
        {
          property: "og:title",
          content: "타이틀",
        },
      ]}
    >
      <Containers.AuthContainer />
    </Page>
  );
}

export default ResetPassword;
