export const tests = {
  name: {
    test: /^[a-z]+([a-z- ',.-]?)+[a-z.]+$/i,
    error: "Please enter a valid name",
  },
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    error: "Please enter a valid email address.",
  },
  password: {
    test: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z]).*$/,
    error:
      "Password must be at least 8 characters in length, and contain characters and numbers.",
  },
  phone: {
    test: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
    error: "Please enter a valid phone number.",
  },
  // tel: {
  //   test: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  //   error: "Please enter a valid tel number.",
  // },
};

/**
 * import * as validate from "utils/validate";
 *   const vali = validate.default(
        {
          email: data.get("email"),
        },
        validate.tests
      )
      => vali.isError false // success
 * @param {*} vs 
 * @param {*} ts 
 * @returns 
 */
const validate = (vs, ts) => {
  const errors = {};
  let isError = false;
  Object.keys(vs).forEach((key) => {
    if (ts[key]) {
      const { test, error, options = {} } = ts[key];
      if (test && error) {
        const res =
          typeof test === "function" ? test(vs[key]) : test.test(vs[key]);
        const skip = options.ifNotEmpty && !vs[key].toString().length > 0;
        if (!res && !skip) {
          isError = true;
          errors[key] = error;
        }
      }
    }
  });
  return { isError, errors };
};

export default validate;
