import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";
// import * as Layouts from "layouts/dashboard";
import * as Layouts from "components/layouts";
import * as Navs from "components/nav";

/**
 *
 * @param {*} props
 * @returns
 */

function Setting(props) {
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  useEffect(() => {
    Libs.showLoading({ opacity: 0.5 });
    Libs.hideLoading();
  }, []);

  return (
    <>
      <Page>
        <Layouts.DashboardLayout
          header={<Containers.HeaderContainer />}
          nav={<Containers.DashboardNavContainer />}
        >
          <Containers.SettingContainer />
        </Layouts.DashboardLayout>

        {/* <Containers.FooterContainer /> */}
      </Page>
    </>
  );
}

export default React.memo(Setting);
