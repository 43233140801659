import { css } from "styled-components";
import { createGlobalStyle } from "styled-components";
import { mixins } from "./mixins";
import { resetcss } from "./reset";
import { globalcss } from "./global";

export const device = {
  pc1600: "1600px",
  pc1300: "1300px",
  pc1020: "1020px",
  pc870: "870px",
  pc630: "630px",

  pc1300Index: 1300,
  pc1020Index: 1020,
  pc630Index: 630,
};

export const styles = {
  main_wapper: "1250px",
};

export const colors = {
  // white
  title_white: `#ffffff`,
  text_white: `#e7e9ea`,
  bg_white: `#EFF3F4`,
  main_white: `#e7e9ea`,
  // gray
  line_gray: `#dbdbdb7d`,
  text_gray: `#ccc`,
  btn_gray: `#d7dbdc`,
  weak_gray: `#d5d6d8`,
  gra_bg_gray: `linear-gradient(to bottom,#eaeaea 0%,#fafafa 100%)`,
  bottom_list_line_gray: `#00000026`,

  // black
  main_black: `#0b0b0b`,
  hover_black: `#16181c`,
  weak_hover_black: `#272a30`,
  bg_black_opa5: `rgba(11, 11, 11, 0.86)`,
  // bg_black: `#1B1C1E`,
  bg_black: `#1d1d1f`,
  bg_black_1: `#1E1E1E`,
  hover_gray_black: `#2a2c2f`,
  hover_deep_black: `#0f0f0f`,

  // green
  main_green: `#00abab`,
  deep_green: `#3da693`,
  light_green: `#3eccb2`,
  bg_green: `rgba(62, 204, 178, 0.2)`,
  up_green: `#16c784`,
  chat_green: `#13B497`,

  // red
  main_red: `#C51F1F`,
  bg_red: `rgba(244, 33, 46, 0.2)`,
  down_red: `#ea3943`,
  light_red: `#ef5454`,
  chat_pink: `rgba(234, 57, 67, 0.33)`,

  // bleu
  main_blue: `#1d9bf0`,
  bg_blue: `#1d9bf033`,
  text_blue: `#5398FF`,
  link_blue: `#06c`,
  link_right_blue: `#2997ff`,

  // orange
  main_orange: `#faa628`,
  bg_orange: `rgba(231, 131, 9, 0.2)`,

  // pink
  main_pink: `#f91880`,
  down_pink: `#ff6899`,
  bg_pink: `rgba(249, 24, 128, 0.2)`,

  // main_shadow: `rgb(255 255 255 / 20%) 0px 0px 15px, rgb(255 255 255 / 15%) 0px 0px 3px 1px`,
  main_shadow: `4px 4px 5px #2d2d2d8c`,
  msg_shadow: `-2px -2px 5px #2d2d2d8c`,

  // beige
  main_beige: `#f5f6ef`,
};

export const fontConfig = {
  nav_text: "",
};

// export function fonts(config = {}) {
//   const {
//     color = colors.text_white,
//     size = "15px",
//     weight = "normal",
//   } = config;
//   return `
//     color:${color};
//     font-size:${size};
//     font-weight:${weight};
//     letter-spacing:-0.5px;
//   `;
// }

export const sizes = {
  header: {
    x: 0,
    y: "65px",
    msgY: "189px",
    mobile: {
      y: "53px",
    },
  },
  banner: {
    title: {
      fontSize: "52px",
    },
    h2title: {
      fontSize: "45px",
    },
    subtitle: {
      fontSize: "38px",
    },
    label: {
      fontSize: "20px",
    },
    text: {
      fontSize: "18px",
    },
  },
  tablet: {
    banner: {
      title: {
        fontSize: "4.444vw",
        letterSpacing: "-2px",
      },
      h2title: {
        fontSize: "3.444vw",
      },
      subtitle: {
        fontSize: "2.444vw",
      },
    },
  },
  mobile: {
    banner: {
      label: {
        fontSize: "4.944vw",
      },
      title: {
        fontSize: "6.444vw",
        letterSpacing: "-2px",
      },
      h2title: {
        fontSize: "5.244vw",
        letterSpacing: "-1.8px",
      },
      subtitle: {
        fontSize: "5.444vw",
      },
      text: {
        fontSize: "4.444vw",
      },
      sub_text: {
        fontSize: "3.644vw",
      },
    },
  },
};

export const shared = {
  styles,
  colors,
  device,
  sizes,
  mixins: mixins({
    colors,
    styles,
    device,
  }),
  // fonts,
};

export const GlobalStyle = createGlobalStyle`

${resetcss}
${globalcss}
`;
