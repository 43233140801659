import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { render, hydrate } from "react-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { BrowserRouter as Router } from "react-router-dom";
import rootReducer from "./store/modules";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import jQuery from "jquery";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import promiseMiddlerware from "redux-promise";
import { createBrowserHistory } from "history";
import reduxThunk from "redux-thunk";
// import "i18n";
import "babel-polyfill";
import config from "utils/config";
import { HelmetProvider } from "react-helmet-async";

export const history = createBrowserHistory();
global.$ = global.jQuery = jQuery;

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

let middlewares = [];
if (config.IS_DEV_NODE_ENV) {
  middlewares = [
    promiseMiddlerware,
    reduxThunk.withExtraArgument({ history: history }),
    logger,
  ];
} else {
  middlewares = [
    promiseMiddlerware,
    reduxThunk.withExtraArgument({ history: history }),
  ];
}
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <HelmetProvider>
      <Provider
        store={createStoreWithMiddleware(
          rootReducer,
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )}
      >
        <React.StrictMode>
          <Router basename="/" history={history}>
            <App />
          </Router>
        </React.StrictMode>
      </Provider>
    </HelmetProvider>
    // ,container
  );
} else {
  root.render(
    <HelmetProvider>
      <Provider
        store={createStoreWithMiddleware(
          rootReducer,
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )}
      >
        <React.StrictMode>
          <Router basename="/" history={history}>
            <App />
          </Router>
        </React.StrictMode>
      </Provider>
    </HelmetProvider>
    // ,container
  );
}

// root.render(
//   <Provider
//     store={createStoreWithMiddleware(
//       rootReducer,
//       window.__REDUX_DEVTOOLS_EXTENSION__ &&
//         window.__REDUX_DEVTOOLS_EXTENSION__()
//     )}
//   >
//     <React.StrictMode>
//       <HelmetProvider>
//         <App />
//       </HelmetProvider>
//     </React.StrictMode>
//   </Provider>
// );

reportWebVitals();
