import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import config from "utils/config";
import $ from "jquery";
import * as Loader from "components/loader";

export const Provider = {
  loadingPortal: {
    id: "global_loading_layer",
  },
};

export function Portals() {
  return (
    <>
      <NotificationPortal />
      <LoadingPortal />
      {/* <ModalPortal />
      <LoadingBrandPortal /> */}
    </>
  );
}

export function NotificationPortal(props) {
  return (
    <InsertPortal target={config.portals.notify}>
      <div></div>
    </InsertPortal>
  );
}
export function ModalPortal() {
  return (
    <InsertPortal target={config.portals.modal}>
      <div></div>
    </InsertPortal>
  );
}

export function LoadingPortal() {
  return (
    <InsertPortal target={config.portals.loading_layer}>
      <Loader.FullScreenLoader
        setid={Provider.loadingPortal.id}
        defaultHide={true}
      />
    </InsertPortal>
  );
}

export function LoadingBrandPortal() {
  return (
    <InsertPortal target={config.portals.brand_layer}>
      <div></div>
    </InsertPortal>
  );
}

export function InsertPortal(props) {
  const globalPortal = document.getElementById(props.target || "");
  return ReactDOM.createPortal(props.children, globalPortal);
}
