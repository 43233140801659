import React from "react";
import styled from "styled-components";
import { shared } from "styles/common";
import * as Wrappers from "components/wrapper";
import * as Buttons from "components/button";
/**
 *
 * @param {*} props
 * @returns
 */
function MainSection(props) {
  return (
    <Styled.MainSection>
      <div className="MainSection__container">
        <Wrappers.ContentWrapper>
          <div className="MainSection__box">
            <div className="MainSection__title_box">
              더본소프트와 함께 수익을 <br />
              창출해보세요
            </div>
            <div className="MainSection__btn_box">
              <Buttons.BasicLinkButton
                to={"/dashboard"}
                theme="blue"
                size="middle"
              >
                당장 시작하기
              </Buttons.BasicLinkButton>
            </div>
          </div>
        </Wrappers.ContentWrapper>
      </div>
    </Styled.MainSection>
  );
}

const Styled = {
  MainSection: styled.div`
    .MainSection__container {
      padding: 200px 0;
    }
    .MainSection__title_box {
      ${shared.mixins.fonts()};
      font-size: 55px;
      font-weight: bold;
      text-align: center;
    }
    .MainSection__btn_box {
      text-align: center;
      margin-top: 50px;
    }
  `,
};
export default MainSection;
