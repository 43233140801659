import React from "react";
import styled from "styled-components";
import { shared } from "styles/common";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";

/**
 *
 * @param {*} props
 * @returns
 */
export function BasicButton(props) {
  const { children, theme } = props;
  return (
    <Styled.BasicButton>
      <button {...props}>{children}</button>
    </Styled.BasicButton>
  );
}

/**
 *
 * @param {*} props
 * @returns
 */
export function ArrowLinkButton(props) {
  const { children = "바로가기", theme, size, to, hoverReverse } = props;
  return (
    <Styled.ArrowLinkButton>
      {to ? (
        <NavLink
          to={props.to}
          className={cx("ArrowLinkButton", props.className, {
            "-black": theme === "black",
            "-gray": theme === "gray",
            "-white": theme === "white",
            "-black": theme === "black",
            "--blue": theme === "blue",
            "--middle": size === "middle",
            "--small": size === "small",
            "--large": size === "large",
          })}
        >
          <div className="ArrowLinkButton_more_con">
            <div className="ArrowLinkButton_more_text">{children}</div>
            <FontAwesomeIcon
              className="ArrowLinkButton_arrow"
              icon={faAngleRight}
            />
          </div>
        </NavLink>
      ) : (
        <div
          className={cx("ArrowLinkButton", props.className, {
            "-black": theme === "black",
            "-gray": theme === "gray",
            "-white": theme === "white",
            "-black": theme === "black",
            "--blue": theme === "blue",
            "--small": size === "small",
            "--middle": size === "middle",
            "--large": size === "large",
          })}
        >
          <div className="ArrowLinkButton_more_con">
            <div className="ArrowLinkButton_more_text">{children}</div>
            <FontAwesomeIcon
              className="ArrowLinkButton_arrow"
              icon={faAngleRight}
            />
          </div>
        </div>
      )}
    </Styled.ArrowLinkButton>
  );
}

/**
 *
 * @param {*} props
 * @returns
 */
export function BasicLinkButton(props) {
  const {
    children = "바로가기",
    theme,
    size,
    to,
    onClick = () => {},
    width,
    className,
  } = props;
  const setProps = {
    ...props,
  };
  delete setProps.className;

  return (
    <Styled.BasicLinkButton>
      {to ? (
        <NavLink
          // {...props}
          to={props.to}
          onClick={onClick}
          className={cx(`BasicLinkButton`, className, {
            "-black": theme === "black",
            "-white": theme === "white",
            "-purple": theme === "purple",
            "-purple_white": theme === "purple_white",
            "-black_white": theme === "black_white",
            "-blue": theme === "blue",
            "--wide": width === "wide",
            "--middle": size === "middle",
            "--small": size === "small",
            "--large": size === "large",
          })}
          {...setProps}
        >
          {children}
        </NavLink>
      ) : (
        <button
          className={cx(`BasicLinkButton`, className, {
            "-black": theme === "black",
            "-white": theme === "white",
            "-purple": theme === "purple",
            "-purple_white": theme === "purple_white",
            "-black_white": theme === "black_white",
            "-blue": theme === "blue",
            "--wide": width === "wide",
            "--small": size === "small",
            "--middle": size === "middle",
            "--large": size === "large",
          })}
          onClick={onClick}
          {...setProps}
        >
          {children}
        </button>
      )}
    </Styled.BasicLinkButton>
  );
}

const Styled = {
  ArrowLinkButton: styled.div`
    display: inline-flex;
    .ArrowLinkButton {
      &.--middle {
        .ArrowLinkButton_more_con {
          font-size: 18px;
          padding: 15px 45px;
        }
      }
      &.--large {
        .ArrowLinkButton_more_con {
          font-size: 20px;
          padding: 18px 40px;
        }
      }
      &.--small {
        .ArrowLinkButton_more_con {
          font-size: 14px;
          padding: 10px 25px;
        }
      }

      &.-white {
        .ArrowLinkButton_more_con {
          border: 2px solid black;
          color: black;
          background: transparent;
          /* &:hover {
            background: black;
            color: white;
          } */
        }
      }
      &.-gray {
        .ArrowLinkButton_more_con {
          background: transparent;
          color: gray;
          border: 2px solid gray;

          &:hover {
            color: black;
          }
        }
      }
      &.-white {
        .ArrowLinkButton_more_con {
          border: 2px solid black;
          background: white;
          color: black;
        }
      }
      &.-black_white {
        .ArrowLinkButton_more_con {
          border: 2px solid white;
          background: black;
          color: white;
        }
      }
      &.-blue {
        .ArrowLinkButton_more_con {
          background: #0071e3;
          ${shared.mixins.fonts({
            size: "16px",
          })};
          color: white;
          &:hover {
            background: #0077ed;
          }
        }
      }
      &.-black {
        .ArrowLinkButton_more_con {
          border: 2px solid black;
          background: black;
          color: white;

          &:hover {
            /* color: black; */
          }
        }
      }

      &.-default {
      }
    }
    .ArrowLinkButton_more_con {
      display: inline-flex;
      align-items: center;
      gap: 0 10px;
      ${shared.mixins.fonts({
        size: "14px",
      })}
      border: 2px solid transparent;
      background: #dbdbdb;
      color: black;
      padding: 10px 15px;
      border-radius: 30px;
      font-weight: bold;
      letter-spacing: -1px;
      transition: 0.2s ease-out;
      flex-wrap: nowrap;
      word-break: break-all;

      &:hover {
        /* color: black; */
        /* text-decoration: underline; */
        .ArrowLinkButton_arrow {
          animation: 0.5s hoverArrowRightAni alternate ease-in-out;
        }
      }
      div {
        /* flex: 0 0 90%; */
      }
    }
    .ArrowLinkButton_more_text {
      flex: 1 0 auto;
    }

    .ArrowLinkButton_arrow {
      position: relative;
      /* transform: rotate(45deg); */
      transition: 0.3s ease;
    }
    @keyframes hoverArrowRightAni {
      0% {
        right: 0;
      }
      50% {
        right: -5px;
      }
      100% {
        right: 0;
      }
    }
    @media screen and (max-width: ${shared.device.pc1300}) {
      .ArrowLinkButton_more_con {
        flex-shrink: 0;
      }
    }
    @media screen and (max-width: ${shared.device.pc630}) {
      .ArrowLinkButton {
        &.-gray {
          .ArrowLinkButton_more_con {
            font-size: 12px;
          }
        }
      }
      .ArrowLinkButton_more_con {
        font-size: 12px;

        .ArrowLinkButton_more_text {
          font-size: ${shared.sizes.mobile.banner.sub_text.fontSize};
          flex: 1 0 auto;
        }
      }
    }
  `,
  BasicButton: styled.div`
    display: inline-block;
    button {
      background: #f2f2f2;
      border-radius: 30px;
      border: 0;
      ${shared.mixins.fonts({
        size: "16px",
      })};
      letter-spacing: -1px;
      color: black;
      padding: 5px 20px;
      cursor: pointer;
    }
    font-size: ${shared.sizes.banner.h2title.fontSize};
  `,
  BasicLinkButton: styled.div`
    .BasicLinkButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background: #f2f2f2;
      border: 2px solid black;
      border-radius: 30px;
      border: 0;
      ${shared.mixins.fonts({
        size: "16px",
      })};
      font-weight: bold;
      letter-spacing: -1.5px;
      color: black;
      padding: 10px 20px;
      text-decoration: none;
      cursor: pointer;
      transition: 0.2s ease-out;
      &.--wide {
        padding: 15px 50px;
      }
      &.--middle {
        padding: 15px 45px;
      }
      &.--large {
        font-size: 20px;
        padding: 16px 40px;
      }
      &.--small {
        font-size: 14px;
        padding: 10px 25px;
      }

      &.-black {
        opacity: 0.9;
        color: white;
        background: black;
        border: 2px solid black;
        &:hover {
          opacity: 1;
          /* border: 2px solid black;
          background: white;
          color: black; */
        }
      }
      &.-white {
        border: 2px solid black;
        background: white;
        color: black;
      }
      &.-black_white {
        border: 2px solid white;
        background: black;
        color: white;
      }
      &.-blue {
        background: #0071e3;
        border: 2px solid #0071e3;
        color: white;
        &:hover {
          background: #0077ed;
        }
      }
      &.-purple {
        background: #5d298b;
        border: 2px solid #5d298b;
        color: white;
        &:hover {
          background: #63239c;
        }
      }
      &.-purple_white {
        border: 2px solid #e4e4e4;
        background: #e4e4e4;
        color: #5d298b;

        &:hover {
          /* background: #63239c; */
          background: #f2f2f2;
          border: 2px solid #f2f2f2;
          color: #5d298b;
        }
      }
    }
    @media screen and (max-width: ${shared.device.pc630}) {
      .BasicLinkButton {
        &.--blue {
          font-size: ${shared.sizes.mobile.banner.sub_text.fontSize};
        }
        &.--middle {
          padding: 12px 30px;
          font-size: ${shared.sizes.mobile.banner.sub_text.fontSize};
        }
        &.--large {
          font-size: 16px;
          font-size: ${shared.sizes.mobile.banner.sub_text.fontSize};
          padding: 15px 35px;
        }
      }
    }
  `,
};
