import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Headers from "components/header";
import * as Containers from "containers";

/**
 *
 * @param {*} props
 * @returns
 */

function Home(props) {
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    Libs.showLoading({ opacity: 0.5 });
    Libs.hideLoading();
  }, []);

  return (
    <>
      <Page>
        <Containers.HeaderContainer typed="main" />
        <Containers.HomeContainer />
        <Containers.FooterContainer />
      </Page>
    </>
  );
}

export default React.memo(Home);
