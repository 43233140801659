import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Headers from "components/header";
import * as Containers from "containers";
import { v4 as uuid4 } from "uuid";
import * as Firebases from "utils/firebase";
import Button from "@mui/material/Button";
/**
 *
 * @param {*} props
 * @returns
 */

const collectionName = "partners_users";
function DashboardContainer(props) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const DashboardState = {
    sales_code: null,
  };
  const [values, setValues] = useImmer(DashboardState);
  const AuthReducer = useSelector((state) => state.auth);
  const getLoginUserInfo = AuthReducer.data.user_info;

  // NOTE:
  const handleClick = async (_config) => {
    const { typed } = _config;
    if (typed === "create_code") {
      if (getLoginUserInfo?.sales_code) {
        alert("이미 영업코드가 존재합니다.");
        return;
      }
      const makeSalseCode = uuid4().split("-").join("");

      const { isError: getDocError, item } =
        await Firebases.getSearchFirebaseDoc({
          collectionName,
          keyword: getLoginUserInfo?.email,
          searchIndex: "email",
        });

      if (getDocError !== false) {
        alert("계정을 확인할 수 없습니다.");
        return;
      }

      const { isError: updateDocError, origin } =
        await Firebases.updateFirebaseDoc({
          collectionName: collectionName,
          documentId: item.document_id,
          data: {
            sales_code: makeSalseCode,
          },
        });

      if (updateDocError !== false) {
        alert("영업코드 업로드에 실패하였습니다.\n잠시후 다시 시도해주세요.");
        return;
      }

      alert("영업코드가 생성되었습니다.");
      setValues((draft) => {
        draft.sales_code = makeSalseCode;
      });
    }
  };

  useEffect(() => {
    if (getLoginUserInfo?.sales_code) {
      setValues((draft) => {
        draft.sales_code = getLoginUserInfo?.sales_code;
      });
    }
  }, []);

  return (
    <>
      <Page>
        <DashboardMain salseCode={values.sales_code} onClick={handleClick} />
      </Page>
    </>
  );
}

function DashboardMain(props) {
  const { salseCode, onClick } = props;

  return (
    <>
      {!salseCode && (
        <>
          <Button
            variant="outlined"
            onClick={() => onClick({ typed: "create_code" })}
          >
            영업 코드생성
          </Button>
        </>
      )}

      <div>{salseCode}</div>
    </>
  );
}

export default React.memo(DashboardContainer);
