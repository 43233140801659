import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import * as AuthActions from "store/modules/auth";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Menus from "components/menu";
/**
 *
 * @param {*} props
 * @returns
 */
function DashboardHeader(props) {
  const { isLogged, onLogout, onSignout, user_info } = props;
  const navigate = useNavigate();
  console.log(isLogged);

  return (
    <Styled.DashboardHeader>
      <div className="DashboardHeader__container">
        <div className="DashboardHeader__box">
          <div>-</div>
          <div>
            {isLogged && (
              <div className="DashboardHeader__profile_box">
                <div className="DashboardHeader__profile_menu">
                  <Menus.ProfileMenu
                    onLogout={onLogout}
                    onSetting={() => navigate("/setting")}
                    onDashboard={() => navigate("/dashboard")}
                    email={user_info?.email}
                  />
                </div>
                <div className="DashboardHeader__profile_label">
                  {user_info?.email_verified ? "인증됨" : "미인증"}
                </div>
              </div>
            )}

            {/* <NavLink to={"/"}>홈</NavLink>
          <NavLink to={"/dashboard"}>대시보드</NavLink>
          <NavLink to={"/sales_list"}>영업리스트</NavLink>
          <NavLink to={"/setting"}>세팅</NavLink> */}
            {/* <NavLink to={"/change_password"}>비밀번호변경</NavLink> */}
            {/* <NavLink to={"/find_account"}>비밀번호 찾기</NavLink> */}
            {/* <NavLink to={"/sales_list"}>영업 리스트</NavLink> */}

            {/* {!isLogged && <NavLink to={"/signup"}>회원가입</NavLink>} */}
            {!isLogged && <NavLink to={"/login"}>로그인</NavLink>}

            {/* <a
            href="/"
            onClick={(event) => {
              if (onLogout) {
                event.preventDefault();
                onLogout();
              } else {
              }
            }}
          >
            로그아웃
          </a> */}

            {/* {user_info?.email} */}
          </div>
        </div>
      </div>
    </Styled.DashboardHeader>
  );
}
const Styled = {
  DashboardHeader: styled.header`
    /* background-image: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    top: 0px;
    left: auto;
    right: 0px;
    color: rgb(255, 255, 255);
    box-shadow: none;
    height: 92px;
    z-index: 1101;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: calc(100% - 281px); */
    padding: 0 16px;
    .DashboardHeader__profile_menu {
      cursor: pointer;
    }
    .DashboardHeader__profile_box {
      text-align: center;
    }
    .DashboardHeader__profile_label {
      align-self: center;
      padding: 3px 10px;
      border-radius: 9px;
      background-color: rgba(231, 131, 9, 0.2);
      color: rgb(250, 166, 40);
      font-size: 10px;
      font-weight: bold;
      letter-spacing: -0.5px;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      text-align: center;
      line-height: 12px;
      margin-top: 5px;
    }
    .DashboardHeader__box {
      display: flex;
      gap: 10px;
      padding: 5px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    a {
      display: inline-block;
      text-decoration: none;
      color: black;
      padding: 3px;
      &.active {
        font-weight: bold;
      }
    }
  `,
};

export default DashboardHeader;
