import React, { useEffect, useRef } from "react";
import * as Headers from "components/header";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Page } from "pages";
import { useImmer } from "use-immer";
import _ from "lodash-es";
import * as Libs from "utils/lib";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import * as Firebases from "utils/firebase";
import * as Wrappers from "components/wrapper";
import * as Sections from "components/section";

/**
 *
 * @param {*} props
 * @returns
 */

function HomeContainer(props) {
  const AuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = !!AuthReducer?.data.user_info?.email;

  return (
    <>
      <Page>
        <HomeMain />
      </Page>
    </>
  );
}

function HomeMain(props) {
  return (
    <>
      <div>
        <Wrappers.SectionWrapper id="banner">
          <Sections.MainSection />
        </Wrappers.SectionWrapper>

        <Wrappers.SectionWrapper id="">
          <Sections.WhySection />
        </Wrappers.SectionWrapper>

        <Wrappers.SectionWrapper id="">
          <Sections.WhySection />
        </Wrappers.SectionWrapper>

        <Wrappers.SectionWrapper id="">
          <Sections.WhySection />
        </Wrappers.SectionWrapper>

        <Wrappers.SectionWrapper id="">
          <Sections.WhySection />
        </Wrappers.SectionWrapper>

        <Wrappers.SectionWrapper id="">
          <Sections.WhySection />
        </Wrappers.SectionWrapper>
      </div>
    </>
  );
}

export default React.memo(HomeContainer);
