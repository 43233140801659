import React from "react";
import styled from "styled-components";
import { shared } from "styles/common";
import * as Wrappers from "components/wrapper";
/**
 *
 * @param {*} props
 * @returns
 */
function WhySection(props) {
  return (
    <Styled.WhySection>
      <div className="WhySection__container">
        <Wrappers.ContentWrapper>
          <div className="WhySection__box">
            <div className="WhySection__title_box"></div>
          </div>
        </Wrappers.ContentWrapper>
      </div>
    </Styled.WhySection>
  );
}

const Styled = {
  WhySection: styled.div`
    .WhySection__container {
      padding: 400px 0;
      border: 1px solid #ececec;
    }
    .WhySection__title_box {
      ${shared.mixins.fonts()};
      font-size: 55px;
      font-weight: bold;
      text-align: center;
    }
  `,
};
export default WhySection;
