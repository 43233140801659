import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import * as validate from "utils/validate";
import { useImmer } from "use-immer";
import * as Containers from "containers";
import * as AuthActions from "store/modules/auth";
import { useSelector, useDispatch } from "react-redux";
import * as C from "utils/constants";
import * as Libs from "utils/lib";
import * as Loaders from "components/loader";
import * as Firebases from "utils/firebase";
import { useNavigate, NavLink } from "react-router-dom";
import * as Urls from "utils/urls";
import circle_logo from "assets/images/image/circle_logo.png";
import google_icon from "assets/images/icons/google_icon.webp";
import kakao_icon from "assets/images/icons/kakao_icon.png";

// NOTE:
function Copyright(props) {
  return (
    <Typography color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://thebornsoft.com/" target={"_blank"}>
        THEBORN SOFT.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

/**
 *
 * @param {*} props
 * @returns
 */
function AuthContainer(props) {
  const { onSubmit } = props;
  const LoginState = {
    is_loaded: null,
  };
  const [values, setValues] = useImmer(LoginState);
  const adminAuthReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading =
    adminAuthReducer?.data.login.is_logged === C.PAGE_LOADING.LOAD_START;

  // NOTE:
  const handleClick = async (_config) => {
    const { typed } = _config;
    // provider 로그인
    if (typed === "google_signin") {
      await Firebases.signinWithSocial({
        provider: "google",
        collectionName: "partners_users",
      }).then((res) => {
        Libs.showLoading({ opacity: "0.5" });
        console.log("login fail");
        console.log(res);
        if (res.isError === false) {
          const formData = {
            email: res?.user?.email,
            user: res?.user,
            provider: "google",
          };
          dispatch(AuthActions.login(formData)).then((res) => {
            if (res.isError === false) {
              Libs.hideLoading();
              navigate("/dashboard");
            } else {
              alert("아이디와 비밀번호를 확인해주세요");
            }
          });
        }
      });
      Libs.hideLoading();
    }

    if (typed === "kakao_signin") {
      kakaoLogin({
        successCallback: async (kakaoLoginData) => {
          Libs.showLoading({ opacity: "0.5" });

          console.log(kakaoLoginData);

          await Firebases.signinWithSocial({
            provider: "kakao",
            collectionName: "partners_users",
            socialData: kakaoLoginData,
          }).then((res) => {
            if (res.isError === false) {
              const formData = {
                email: res?.user?.email,
                user: res?.user,
                socialData: kakaoLoginData,
                provider: "kakao",
              };
              console.log(formData);

              dispatch(AuthActions.login(formData)).then((res) => {
                if (res.isError === false) {
                  Libs.hideLoading();
                  navigate("/dashboard");
                } else {
                  alert("아이디와 비밀번호를 확인해주세요");
                }
              });
            }
          });
          Libs.hideLoading();
        },
      });
    }
  };
  // NOTE:
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const formData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    const vali = validate.default(
      {
        email: data.get("email"),
        password: data.get("password"),
      },
      validate.tests
    );

    // 벨리데이션 체크
    if (vali.isError) {
      alert("아이디와 비밀번호를 확인해주세요");
      return;
    }

    dispatch(AuthActions.login(formData)).then((res) => {
      if (res.isError === false) {
        console.log("로그인. 대시보드 redirect");
        // navigate(Urls.urls.admin.dashboard.index);
        navigate("/dashboard");
      } else {
        alert("아이디와 비밀번호를 확인해주세요");
      }
    });
    // firebase와 통신 후 처리
  };
  const kakaoLogin = ({ successCallback }) => {
    console.log(window?.Kakao);
    if (window?.Kakao) {
      const Kakao = window?.Kakao;
      console.log(window?.Kakao);
      Kakao.Auth.login({
        success() {
          Kakao.API.request({
            url: "/v2/user/me",
            success(res) {
              const kakaoAccount = res.kakao_account;
              console.log(kakaoAccount);
              const kakaoLoginInfo = {
                email: kakaoAccount.email,
                profile_image: kakaoAccount.profile.nickname,
                nickname: kakaoAccount.profile.profile_image_url,
              };

              console.log("kakao res");
              console.log(res);
              return;
              if (successCallback) {
                console.log(kakaoLoginInfo);
                successCallback(kakaoLoginInfo);
              }
            },
            fail(error) {
              console.log(error);
            },
          });
        },
        fail(error) {
          console.log(error);
        },
      });
    }
  };
  return (
    <Styled.Login>
      <div className="App">
        {/* <h2>카카오 로그인 구현</h2>
        <button onClick={kakaoLogin}>
          <img
            src="//k.kakaocdn.net/14/dn/btroDszwNrM/I6efHub1SN5KCJqLm1Ovx1/o.jpg"
            width="222"
            alt="카카오 로그인 버튼"
          />
        </button> */}
      </div>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Login__logo">
              <img
                src={circle_logo}
                alt="로그인 로고"
                className="Login__logo_img"
              />
            </div>
            <div className="Login__title">더본소프트 파트너스</div>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={"thebornsoft@gmail.com"}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={"00000000tb!"}
              /> */}
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button> */}

              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          {/* <button onClick={handleLogout}>Logout</button> */}
          {/* <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleAutoLogin}
          >
            Auto Login (TEST)
          </Button> */}
          <div className="Login__bottom_social_btn_box">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => handleClick({ typed: "google_signin" })}
              className="Login__social_text"
            >
              <img
                src={google_icon}
                alt="로그인 로고"
                className="Login__social_icon_img"
              />
              구글 로그인
            </Button>
            {/* <Button
              variant="outlined"
              fullWidth
              onClick={() => handleClick({ typed: "kakao_signin" })}
              className="Login__social_text"
            >
              <img
                src={kakao_icon}
                alt="로그인 로고"
                className="Login__social_icon_img"
              />
              카카오 로그인
            </Button> */}
          </div>

          <div className="Login__bottom_btn_box">
            {/* <NavLink
              color="inherit"
              to={"/reset_password"}
              className="Login__signup__text"
            >
              Reset Password
            </NavLink> */}
            {/* <NavLink
              color="inherit"
              to={"/signup"}
              className="Login__signup__text"
            >
              Create Account
            </NavLink> */}
          </div>
          <Copyright sx={{ mt: 8, mb: 4 }} className="Copyright" />
        </Container>
      </ThemeProvider>
    </Styled.Login>
  );
}

const Styled = {
  Login: styled.div`
    .MuiInputLabel-outlined {
      /* top: -8px; */
    }
    .MuiOutlinedInput-input {
      /* padding: 18px 15px; */
      /* height: 3.435em; */
    }
    .MuiButtonBase-root {
      padding: 13px 16px;
    }
    .MuiBox-root {
      /* margin-top: 50px; */
    }
    .MuiFormControl-root {
      margin-top: 4px;
    }
    .Login__logo {
      width: 60px;
      height: 60px;
      margin-bottom: 20px;
      .Login__logo_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .Login__bottom_btn_box {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 40px;
    }
    .Login__title {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .Login__signup__text {
      font-size: 13px;
      letter-spacing: -0px;
      color: blue;
    }
    .Login__social_text {
      font-size: 15px;
      letter-spacing: -0px;
      color: #0000d3;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    .Login__social_icon_img {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 100%;
      overflow: hidden;
    }
    .Login__bottom_social_btn_box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .Copyright {
      margin: 10px;
      a {
        font-size: 13px;
        letter-spacing: -0px;
      }
    }
  `,
};

export default AuthContainer;
