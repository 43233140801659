import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import logo from "assets/images/image/logo.svg";
import { shared } from "styles/common";
/**
 *
 * @param {*} props
 * @returns
 */
function DashboardNav(props) {
  const { isLogged, email } = props;
  console.log(email);
  return (
    <Styled.DashboardNav>
      <div className="DashboardNav__container">
        <div className="DashboardNav__header">
          <div className="DashboardNav__logo_box">
            <img src={logo} alt="" className="DashboardNav__logo_img" />
            <div className="DashboardNav__logo_title">
              THEBORNSOFT <br />
              PARTNERS
            </div>
          </div>
          <div className="DashboardNav__header_profile_con">
            <NavLink to={"/setting"} className="">
              <div className="DashboardNav__header_card">
                <div className="DashboardNav__profile_box">
                  {email[0]?.toUpperCase()}
                </div>
                <div className="DashboardNav__info_item">
                  <h6 className="DashboardNav__username">Junyeong Lee</h6>
                  <p className="DashboardNav__email">admin</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="DashboardNav__box">
          <div className="DashboardNav__title">GENERAL</div>
          <ul>
            <li>
              <NavLink to={"/dashboard"} className="DashboardNav__link">
                대시보드
              </NavLink>
            </li>
            <li>
              <NavLink to={"/sales_list"} className="DashboardNav__link">
                세일즈 리스트
              </NavLink>
            </li>
            <li>
              <NavLink to={"/setting"} className="DashboardNav__link">
                세팅
              </NavLink>
            </li>
            <li>
              <NavLink to={"/"} className="DashboardNav__link">
                홈으로 가기
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </Styled.DashboardNav>
  );
}

const Styled = {
  DashboardNav: styled.div`
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    /* position: fixed; */
    top: 0px;
    outline: 0px;
    left: 0px;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
    z-index: 0;
    width: 280px;
    background-color: transparent;
    padding-top: 50px;

    .DashboardNav__logo_box {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .DashboardNav__logo_title {
      ${shared.mixins.fonts()};
      font-weight: bold;
      font-size: 17px;
      line-height: 1.2;
    }
    .DashboardNav__logo_img {
      width: 40px;
      height: 30px;
      display: inline-flex;
    }
    .DashboardNav__header_profile_con {
      margin-top: 30px;
    }
    .DashboardNav__header {
      padding: 0 16px;
    }
    .DashboardNav__profile_box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      font-family: "Public Sans", sans-serif;
      font-size: 1.25rem;
      line-height: 1;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      color: rgb(255, 255, 255);
      background-color: rgb(255, 86, 48);
      background-color: rgb(77 168 142);
      font-weight: 600;
    }
    .DashboardNav__header_card {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      border-radius: 12px;
      background-color: rgba(145, 158, 171, 0.12);
    }
    .DashboardNav__info_item {
      margin-left: 16px;
      min-width: 0px;
    }
    .DashboardNav__username {
      margin: 0px;
      font-weight: 600;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: "Public Sans", sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .DashboardNav__email {
      margin: 0px;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-family: "Public Sans", sans-serif;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgb(99, 115, 129);
    }
    .DashboardNav__box {
      padding: 0 16px;
    }

    .DashboardNav__title {
      box-sizing: border-box;
      list-style: none;
      padding: 24px 16px 8px;
      font-weight: 700;
      line-height: 1.5;
      font-size: 11px;
      text-transform: uppercase;
      font-family: "Public Sans", sans-serif;
      color: rgb(99, 115, 129);
    }

    .DashboardNav__link {
      -webkit-tap-highlight-color: transparent;
      background-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px 0px 4px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      min-width: 0px;
      box-sizing: border-box;
      text-align: left;
      padding: 8px 12px 8px 16px;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: relative;
      text-transform: capitalize;
      color: rgb(99, 115, 129);
      border-radius: 8px;
      height: 48px;
      &.active {
        color: rgb(0, 171, 85);
        background-color: rgba(0, 171, 85, 0.08);
        font-weight: bold;
        &:hover {
          color: rgb(0, 171, 85);
          background-color: rgba(0, 171, 85, 0.08);
        }
      }
      &:hover {
        text-decoration: none;
        background-color: rgba(145, 158, 171, 0.08);
      }
    }
  `,
};

export default DashboardNav;
