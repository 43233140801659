// SEOMetaTag.js
import React from "react";
import { Helmet } from "react-helmet-async";

/**
 *
 * @param {*} props
 * @returns
 */

const locales = {
  en: "en_US",
  ko: "ko_KR",
};

const Meta = (data) => {
  let siteName = "더본소프트";
  let siteUrl = "https://www.thebornsoft.com";
  if (data?.canonical) {
    siteUrl = `${siteUrl}/${data?.canonical}`;
  }
  const baseLang = "en";
  const lang = locales[data?.locale] || locales[baseLang];
  const title = data?.title;
  let description = data?.description;
  if (typeof description === "string") {
    description = data?.description
      .split(" ")
      .filter((i) => i !== "")
      .join(" ")
      .trim()
      .replace(/&nbsp;/gi, "")
      .replace(/=/gi, "")
      .replace(/"/gi, "");
  }

  const image = data?.image !== undefined && siteUrl + `${data?.image}`;
  const canonical = `${siteUrl}`;
  const type = data?.type === undefined ? "website" : data?.type;
  const width = data?.image && (data?.width || 1280);
  const height = data?.image && (data?.height || 720);
  const keywords = data?.keywords;

  return (
    <Helmet titleTemplate="%s">
      <html lang={baseLang} />
      {title && <title>{title}</title>}
      {/* {description && <meta name="description" content={description} />}
      {keywords && (
        <meta name="keywords" content={keywords} data-react-helmet="true" />
      )} */}
      {/* {canonical && <link rel="canonical" href={canonical} />} */}
      {/* {image && <link rel="image_src" href={image} />}
      {image && <meta itemprop="image" content={image} />} */}

      {/* og default */}
      {/* {siteName && <meta property="og:site_name" content={siteName} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {canonical && <meta property="og:url" content={canonical} />} */}
      {/* {lang && <meta property="og:locale" content={lang} />} */}
      {/* {type && <meta property="og:type" content={type} />}
      {image && <meta property="og:image" content={image} />}
      {width && <meta property="og:image:width" content={width} />}
      {height && <meta property="og:image:height" content={height} />} */}

      {/* facebook */}
      {/* {siteUrl && <meta property="fb:pages" content={siteUrl} />} */}

      {/* change type of twitter if there is no image? */}

      {/* <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {siteUrl && <meta name="twitter:site" content={`@${siteUrl}`} />} */}
      {canonical && (
        <link rel="alternate" href={canonical} hreflang={baseLang} />
      )}
    </Helmet>
  );
};

const MetaTag = (props) => {
  // props로 content 내용을 불러올 예정임
  return (
    <Helmet>
      <title>{props.title}</title>

      <meta
        name="description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta name="keywords" content={props.keywords} data-react-helmet="true" />

      <meta property="og:type" content="website" data-react-helmet="true" />
      <meta
        property="og:title"
        content={props.title}
        data-react-helmet="true"
      />
      <meta
        property="og:site_name"
        content={props.title}
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content={props.imgsrc}
        data-react-helmet="true"
      />
      <meta property="og:url" content={props.url} data-react-helmet="true" />

      <meta
        name="twitter:title"
        content={props.title}
        data-react-helmet="true"
      />
      <meta
        name="twitter:description"
        content={props.description}
        data-react-helmet="true"
      />
      <meta
        name="twitter:image"
        content={props.imgsrc}
        data-react-helmet="true"
      />

      <link rel="canonical" href={props.url} />
    </Helmet>
  );
};

// export default MetaTag;
export default Meta;
